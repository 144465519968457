@import "@/stylesheets/global.less";
.amps-task-item {
  width: 356px;
  cursor: pointer;
  .amps-task-item-body {
    width: 300px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px #dbdee8;
    border-radius: 6px;
    padding: 16px 12px 16px 14px;
    margin-bottom: 12px;
    margin-left: 32px;
    box-sizing: border-box;
    .item-state {
      .assets {
        margin-right: 10px;
      }
      .assets-img {
        width: 16px;
        height: 16px;
      }
      .assets-text,
      .score-text {
        margin-left: 6px;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #19233c;
      }

      .score-img {
        width: 18px;
        height: 18px;
      }
      .state-text {
        // background: #70d9d9;
        border-radius: 2px;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        height: 20px;
        color: #fff;
        width: 50%;
        display: flex;
        align-items: right;
        justify-content: right;
      }
    }

    .item-title {
      margin-top: 9px;
      .title-id,
      .title-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #19233c;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .title-text {
        margin-left: 14px;
      }
      .title-id {
          min-width: 120px;
      }
    }
    .item-images {
      max-width: 272px;
      margin-top: 8px;

      .images-item {
          overflow: hidden;
        background: #000000;
        border-radius: 2px;
        width: 51px;
        height: 51px;
        margin-left: 4px;
        margin-bottom: 4px;
        position: relative;
        .ant-image {
            height: 100%;
            width: 100%;
            img {
                //   width: 32.79px;
                //   height: 48.09px;
                    width: 100%;
                    height: 100%;
            }
        }
        .ellipsis {
          position: absolute;
          width: 100%;
          height: 100%;
          background: #000000;
          opacity: 0.6;
          border-radius: 2px;
          .ellipsis-circle {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #bfc5d1;
          }
          .ellipsis-circle:nth-child(2) {
            margin-left: 4px;
            margin-right: 4px;
          }
        }
      }
      .images-item:first-child,
      .images-item:nth-child(6) {
        margin-left: 0px;
      }
      .images-item:nth-child(n + 5) {
        margin-bottom: 0px;
      }
    }

    .item-details {
      margin-top: 8px;
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #19233c;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }
  .amps-task-item:hover {
    cursor: pointer;
  }
  .item-select {
    border: 1px solid #29c1b9;
    box-sizing: border-box;
  }
}
