@import "@/stylesheets/global.less";
.amps-trip-list {
    .amp-edit-icon {
        font-size: 40px;
        vertical-align: top;
        cursor: pointer;
    }

    .amp-decrease-icon {
        margin-left: 8px;
        font-size: 40px;
        vertical-align: top;
        cursor: pointer;
    }
}
