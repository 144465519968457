@import "@/stylesheets/global.less";
.amp-topic-scene-wrap-scroll {
    padding: 0 12px 16px 16px;

    &::-webkit-scrollbar {
        display: inline;
    }

    &::-webkit-scrollbar-button {
        height: 8px;
    }

    .amp-topic-left-scroll-item {
        margin-top: 16px;
        padding: 8px 16px;
        color: #000;
        font-size: 14px;
        line-height: 22px;
        border: 1px solid #e8e8e8;
        cursor: pointer;

        &:hover {
            background-color: #f0fffc;
            border-color: #29c1b9;
        }
    }

    .amp-topic-line-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .amp-topic-left-scroll-item-current {
        background-color: #f0fffc;
        border-color: #29c1b9;
    }
}
