@import "@/stylesheets/global.less";
/* stylelint-disable declaration-no-important */

/* stylelint-disable selector-max-type */

.amps-select-topic-page {
    margin-top: 20px;

    .amp-select-topic-content {
        height: calc(100vh - 210px);
        overflow-x: auto;
        overflow-y: auto;

        &:first-child {
            margin: 0;
        }
    }

    ::-webkit-scrollbar {
        display: block;
        width: 10px;
    }

    ::-webkit-scrollbar-button {
        height: 0;
    }

    .amp-list-item {
        display: flex;
        width: 98.5%;
        margin-bottom: 16px;
    }

    .amp-item {
        flex: 0 0 20%;
        min-width: 1px;
        margin-right: 6px;
        border: 1px solid #e8e8e8;
        box-shadow: 0 4px 4px 0 #00000008;

        .amp-item-content {
            box-sizing: border-box;
            padding: 8px 16px 16px;
            border: 1px solid transparent;
            border-top: none;
        }

        &:hover > .amp-item-content {
            border-color: #4ccfc4;
        }

        .amp-item-content-middle {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 4px 0 9px;
            color: #19233c;
            font-size: 14px;
        }

        .amp-item-content-bottom {
            height: 40px;
            color: #19233c;
            font-size: 12px;
            line-height: 20px;
        }

        .amp-item-span-border {
            display: flex;
            align-items: center;
            height: 20px;
            padding: 0 8px;
            font-size: 12px;
            white-space: nowrap;
            border: 1px solid #bfbfbf;
            border-radius: 4px;
        }

        .amp-scene-bg-bd {
            color: #52c41a;
            background: #f6ffed;
            border-color: #b7eb8f;
        }

        .amp-theme-bg-bd {
            color: #fa8c16;
            background: #fff7e6;
            border-color: #ffd591;
        }

        .amp-line-clamp-1 {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .amp-line-clamp-2 {
            display: -webkit-box;
            display: -moz-box;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            word-wrap: break-word;
            word-break: break-all;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -moz-line-clamp: 2;
            -moz-box-orient: vertical;
        }
    }

    .amp-item-img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .to-vh(198);

        min-height: 198px;
        max-height: 100%;
        background-color: #000;

        &::after {
            display: block;
            padding-bottom: 61.8%;
            content: "";
        }

        .ant-image {
            width: 100%;
            height: 100%;
        }

        .amp-ant-image {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .amp-item-content-top {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        min-width: 190px;
        color: #000;

        .amp-item-title {
            flex: 1;
            height: 22px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
        }

        .amp-item-tips {
            display: flex;
            font-size: 12px;
        }
    }

    .amp-select-topic-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .amp-select-topic-form {
        padding-bottom: 20px;
    }

    .ant-form-item-label > label {
        font-weight: 500;
    }

    .ant-picker-range {
        width: 100%;
    }

    .ant-select-selection-placeholder {
        width: 1px;
    }

    .amp-filter-form {
        display: flex;
        flex-direction: column;
    }

    .amp-filter-top {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .amp-query-filter {
        flex: 1;
        width: 90%;
    }

    .amp-filter-sort {
        height: 30px;
        padding-bottom: 40px;
        line-height: 30px;
    }

    .amp-filter-sort-form {
        position: absolute;
        right: 0;
        bottom: -16px;
    }

    .amp-filter-title {
        font-weight: 400;
        font-size: 14px;
    }

    .ant-image-error {
        .ant-image-img {
            object-fit: none;
        }
    }
}
