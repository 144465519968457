.ant-notification-notice {
  padding: 14px !important;
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.ant-notification-close-x {
  display: none !important;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0 !important;
}

.notification-content {
  width: 240px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.force-logout {
  width: 550px;
  text-align: left;
  padding: 26px 36px 38px 36px;
  box-sizing: border-box;
}
