@import "@/stylesheets/global.less";
.amps-right-create-tasks {
  width: 904px;
  position: relative;

  .create-tasks-body {
    // max-height: 687px;
    margin: 0px 4px;
    padding: 0 28px 24px 28px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    height: 720px;

    .create-tasks-title {
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      color: #19233c;
      margin-top: 24px;
    }
    .create-tasks-form {
      margin-top: 26px;
      .ant-form-item-label {
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        padding-bottom: 12px !important;
        line-height: 20px;
      }
      .ant-form-item {
        // margin-bottom: 20px;
        // height: 100%;
      }
      .trip-color {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .task-input {
        width: 330px;
        height: 36px;
      }
      .no-border {
        border: none;
      }
      .task-right {
        margin-left: 100px;
      }
      .amps-drawer-form-content {
        margin-top: 12px;
        width: 100%;

        .required {
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          color: #19233c;
          line-height: 20px;
        }
        .required::after {
          display: inline-block;
          box-sizing: border-box;
          top: -2px;
          margin-left: 3px;
          color: #ff4d4f;
          font-size: 14px;
          content: "*";
          font-weight: 300;
          position: relative;
        }
        .plan-table {
          margin-top: 12px;
          .ant-table-cell {
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            color: #19233c;
            height: 50px;
          }
        }
      }
      .task-details {
        margin-top: 20px;
        position: relative;
        .ant-form-item .ant-input-textarea-show-count::after {
          position: absolute;
          bottom: 6px;
          right: 16px;
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
        }
        .details-count {
          position: absolute;
          right: 16px;
          bottom: 8px;
          font-family: Noto Sans SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color:#BFC5D1 ;
          .count-text {
            color: #19233c;
          }
        }
      }
      .task-trips {
        margin-bottom: 0 !important;
        .ant-col {
          min-height: 0;
        }
        .ant-form-item-control-input {
          min-height: 0;
        }
      }
    }
  }
  /* 设置滚动条的样式 */
  .create-tasks-body::-webkit-scrollbar {
    width: 6px;
    display: block !important;
  }
}
