.account-profile {
  box-sizing: border-box;
  padding: 50px 40px 0px 40px;
}

.profile-info-section {
  display: flex;
  flex-flow: row wrap;
  .to-vw(1100);
  justify-content: space-between;
  margin-top: 25px;
}

.validation-code {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.styles_react-code-input__CRulA {
  input {
    font-family: NotoSans !important;
    border: none !important;
    font-size: 32px !important;
    &:last-child {
      border-right: none !important;
    }
    &:focus {
      border: none !important;
      caret-color: black !important;
    }
    text-transform: uppercase !important;
  }
}
.dashed-line {
  margin-left: 3px;
  width: 446px;
  height: 1px;
  background-image: linear-gradient(
    to right,
    #9b9b9b 0%,
    #9b9b9b 88%,
    transparent 50%
  );
  background-size: 74.3px 18px;
  background-repeat: repeat-x;
}

.ant-carousel {
    .slick-dots {
        display: none !important;
    }
}
