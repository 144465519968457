@import "@/stylesheets/global.less";
.amps-bottom-button {
  height: 80px;
  .bottom-button {
    margin-left: 32px;
    // position: absolute;
    // bottom: 30px;
  }
  .tasks-btn {
    width: 120px;
    height: 40px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .tasks-create {
    color: #ffffff;
  }
  .tasks-cancel {
    color: #595959;
  }
}
