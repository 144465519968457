@import "@/stylesheets/global.less";
.amps-relate-info-item {
    margin-bottom: 16px;

    .amp-relate-info-item-title {
        color: #000;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
    }

    .amp-relate-info-item-list {
        margin-top: 8px;
        padding: 8px 16px;
        background: #fafafa;
        cursor: pointer;

        .amp-relate-info-item-id {
            color: #595959;
            font-weight: 400;
            font-size: 12px;
            font-style: normal;
        }

        .amp-relate-info-item-name {
            margin-left: 8px;
            color: #000;
            font-weight: 400;
            font-size: 12px;
            font-style: normal;
        }
    }
}
