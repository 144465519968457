
html,
body {
  overflow: auto;
  font-family: NotoSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.amp {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;

}

u {
  text-underline-offset: 2px;
}

.ant-input {
  border-radius: 4px;
  font-size: 14px !important;
}

.main-wrapper {
  box-sizing: border-box;
}

#router-content {
  padding: 12px 50px 0px 50px;
  box-sizing: border-box;
  margin-top: 68px;
}

.full-content {
  margin: -12px -50px -32px -50px;
  .ant-menu-inline {
    height: calc(100% - 6vh);
  }
  .ant-menu-sub.ant-menu-inline {
    background: #fff !important;
  }
}

.ant-scrolling-effect {
  width: 100% !important;
}

.side-content {
  padding: 0 50px 0 50px;
  width: 0;
  flex: 1;
  .ant-table-body {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block;
      width: 8px;
      border-radius: 4px;
    }
  }
}

.header-wrapper {
  position: fixed;
  top: 0;
  z-index: 501;
  width: 100%;
}

.z-index-important {
  z-index: 1000 !important;
}


.ant-image-preview-operations {
  z-index: 2000 !important;
}

.blank-content {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  h2 {
    color: #c2c2c2;
  }
  img {
    width: 30vw;
    margin-left: -20px;
    margin-bottom: -90px;
  }
}
.amap-container {

    .amap-logo{
        display: none !important;
        height: 0;
        width: 0;
    }

    .amap-copyright{
        display: none !important;
        height: 0;
        width: 0;
    }
}
