@import "@/stylesheets/global.less";
.amp-topic-relation-scene {
    padding-bottom: 24px;

    .amp-topic-scene-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    .amp-scene-header-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #000;
        font-weight: 500;
        line-height: 32px;
        size: 14px;
    }

    .amp-topic-scene-header-btns {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
    }

    .amp-scene-header-btns-btn {
        width: auto;
        height: 32px;
        margin-left: 8px;
    }

    .amp-topic-relation-scene-scroll {
        width: 100%;
        padding: 5px;
        box-shadow: inset 0 -1px 10px rgba(0, 0, 0, 0.1);
    }

    .amp-scene-scroll-line {
        display: flex;
    }

    .amp-scene-scroll-line-item {
        width: 33.33%;
        padding: 5px;
        overflow: hidden;
    }

    .amp-scene-item-wrap {
        border: 1px solid #e8e8e8;
        cursor: pointer;
        // border-radius: 4px;
    }

    .amp-scene-item-wrap-pic {
        position: relative;
        padding-top: 61.8%;
    }

    .ant-image,
    .amp-scene-item-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .amp-scene-item-content {
        padding: 8px;
    }

    .amp-scene-item-content-name {
        margin-bottom: 4px;
        overflow: hidden;
        color: #000;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .amp-scene-item-content-number {
        margin-right: 8px;
        color: #19233c;
        font-size: 12px;
        line-height: 20px;
    }

    .amp-scene-item-content-tag {
        padding: 0 8px;
        overflow: hidden;
        color: #999;
        font-size: 12px;
        line-height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
    }

    .ant-image-error {
        background-color: #000;

        .ant-image-img {
            object-fit: none;
        }
    }
}
