.ant-picker,
.ant-select,
.ant-picker-input {
  height: 40px;
  input {
    font-size: 14px !important;
  }
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 16px !important;
  font-weight: 700;
  font-size: 14px !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    display: none;
  }

  &::after {
    display: inline-block;
    box-sizing: border-box;
    margin-top: -3px;
    margin-left: 3px;
    color: #ff4d4f;
    font-size: 14px;
    content: "*";
  }
}

.two-col-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .form-item {
    width: 260px;
  }
  .form-item-330 {
    width: 330px;
  }
}

.span-form {
  width: 100%;
}

.three-col-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .form-item {
    .to-vw(230);
  }
}

.select-people {
  display: flex;
  align-items: center;

  .ant-select {
    width: 175px !important;
    margin-right: 30px;
  }

  padding-top: 30px;
}

.drawer-form-content {
  margin-top: 40px;
}

.plan-table-form {
  background: #f5f5f5;
  padding-left: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  flex-wrap: nowrap;

  .ant-btn {
    padding: 0;
  }
}


.plan-form-edit {
  margin-top: 4px;
  .to-vw(80);
  display: flex;
  margin-left: 10px;
  .ant-btn {
    padding: 0 !important;
    margin-right: 10px;
  }
}

.ant-select-selection-overflow {
  flex-wrap: nowrap;
}

.process-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}


.process-form-col-1 {
  width: 100%;
}

.process-form-col-2 {
  width: 40%;
  min-height: 95px;
}


.task-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  .asset-count {
    .ant-input-number-input-wrap {
        height: 100% !important;
        input {
          height: 100% !important;
        }
    }
  }
}

.task-form-col-1 {
  width: 100%;
}

.task-form-col-2 {
  width: 50%;
  .to-vw(116, min-height);
  .ant-input, .ant-select {
    // .to-vw(330) !important;
  }
}

.add-edit-description {
  textarea {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: block;
      width: 3px; /* 纵向滚动条*/
    }
    &::-webkit-scrollbar-track {
      border: 2px solid transparent;
      background-clip: content-box; /* THIS IS IMPORTANT */
    }
    &::-webkit-scrollbar-button {
      height: 0 !important;
      width: 0 !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #c8c8c8 !important;
      border-radius: 40px;
      width: 50%;
      border: 2px solid transparent;
      &:horizontal:hover {
        background: #b6b6b6 !important;
      }
    }
  }
}
