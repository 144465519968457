@import "@/stylesheets/global.less";
.amps-task-package-operate {
    .amp-operate-title {
        display: flex;
        align-items: center;
        color: #19233c;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
    }

    .task-form {
        .task-form-col-2 {
            min-height: 3.3vw;
        }
    }

    .special-color {
        color: #EC3B3B !important;
    }
}
