.details {
    .bold {
        width: 56px;
        text-align: right;
    }
    .reset-filter {
        padding-left: 10px;
    }
    form {
        div.site-input-group-wrapper.total{
            margin-right: 14px;
        }
    }
}