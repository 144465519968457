@import "@/stylesheets/global.less";
@zindex-preview-mask: 1000;
@preview-mask-bg: fade(#000, 100%);
@text-color: #bbb;
@text-color-disabled: darken(@text-color, 30%);
@background-color: #f3f3f3;
.amps-preview-image-modal {
    top: 0;
    width: 100%;
    padding: 0;
    max-width: 100%;
}

.amps-preview-image {
    user-select: none;
    position: relative;
    height: 100VH;
    width: 100%;

    &-operations {
        pointer-events: auto;
        list-style: none;
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        width: 100%;
        align-items: center;
        flex-direction: row-reverse;
        z-index: 2000;
        color: @text-color;
        background: fade(@preview-mask-bg, 45%);

        &-operation {
            padding: 10px;
            cursor: pointer;
            margin-left: 10px;

            &-disabled {
                pointer-events: none;
                color: @text-color-disabled;
            }

            &:last-of-type {
                margin-left: 0;
            }
        }

        &-icon {
            font-size: 18px;
        }
    }

    &-img-wrapper {
        margin: 0 auto;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

    }
    &-img-wrapper:before {
        display: inline-block;
        width: 1px;
        height: 50%;
        margin-right: -1px;
        content: "";
    }
    &-img {
        user-select: none;
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    &-loading {
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }

    &-error {
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }

    &-wrap {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        overflow: auto;
        outline: 0;
        -webkit-overflow-scrolling: touch;
    }

    &-switch-left {
        position: absolute;
        left: 10px;
        top: 50%;
        width: 44px;
        height: 44px;
        margin-top: -22px;
        background: fade(@text-color, 45%);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2000;
        pointer-events: auto;
        cursor: pointer;
        color: @text-color;

        &-disabled {
            background: fade(@text-color, 30%);
            color: @text-color-disabled;
            cursor: not-allowed;

            > .anticon {
                cursor: not-allowed;
            }
        }

        > .anticon {
            font-size: 24px;
        }
    }

    &-switch-right {
        position: absolute;
        right: 10px;
        top: 50%;
        width: 44px;
        height: 44px;
        margin-top: -22px;
        background: fade(@text-color, 45%);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2000;
        pointer-events: auto;
        color: @text-color;
        cursor: pointer;

        &-disabled {
            background: fade(@text-color, 20%);
            color: @text-color-disabled;
            cursor: not-allowed;

            > .anticon {
                cursor: not-allowed;
            }
        }

        > .anticon {
            font-size: 24px;
        }
    }
    &-desc{
        position: absolute;
        left: 0;
        top: 0;
    }
}
