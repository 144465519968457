.profile-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -14px !important;
    cursor: default;
}

.ant-avatar {
    cursor: default;
}

.status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.ant-popover-inner-content {
    display: flex;
    max-width: 350px;
    text-align: left;
    flex-flow: row wrap;

    .ant-avatar {
        margin: 5px;
    }
}

.amp-header-storages-popover {
    .ant-popover-title {
        border: none;
        padding: 0 16px;
    }

    .amp-header-storages-popover-title {
        display: flex;
        min-width: 320px;
        justify-content: space-between;
        align-items: center;
        line-height: 40px;
        padding: 5px 0;
        border-bottom: 1px dashed #E5E5E5;
    }

    .amp-header-storages-popover-maintext {
        font-size: 24px;
        color: #29c1b9;
        margin-right: 10px;
    }

    .amp-header-storages-popover-close {
        height: 40px;
        width: 40px;
        text-align: center;
        cursor: pointer;
    }

    .amp-header-storages-popover-content {
        max-height: 360px;
        overflow: auto;
        font-size: 14px;
        flex: 1;
    }

    .amp-header-storages-popover-line {
        display: flex;
        align-items: center;
        min-width: 0px;
        line-height: 36px;
        color: #666;

        &:hover {
            background-color: #F3FFFD;
        }
    }

    .amp-header-storages-popover-taskid {
        min-width: 110px;
        margin-right: 15px;
    }

    .amp-header-storages-popover-taskname {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
    }
}
