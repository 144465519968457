.cascader-default {
  height: 40px;
  display: flex;
  align-items: center;
  margin: 4px 0;
  box-sizing: border-box;
  padding: 0 15px 0 17px;
  cursor: pointer;
  &:hover {
    color: #29c1b9;
  }
  transition: background 0.7s, color 0.3s, cubic-bezier(0.2, 0, 0, 1) 0s;
}

.cascader-selected {
  &:extend(.cascader-default);
  width: 100%;
  background: rgba(41, 193, 185, 0.08);
  color: #29c1b9;
  justify-content: space-between;
  &::after {
    transform: scaleY(1);
    opacity: 1;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.user-checkbox-item {
  width: 100%;
  height: 40px;
  margin: 4px 0;
  padding: 0 0 0 10px;
  line-height: 40px;
  position: relative;
  .ant-checkbox {
    position: absolute;
    right: 20px;
    top: 12px;
  }
}

.ant-select-multiple .ant-select-selector {
  padding: 0 8px !important;
}
