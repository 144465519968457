@import "@/stylesheets/global.less";
.tmp-task-info-images {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    max-height: 372px;
    overflow-y: scroll;
}

.amp-task-info-modal {
    .ant-modal-header {
        padding: 12px 20px;
        .ant-modal-title {
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 36px;
            display: flex;
            align-items: center;
            color: #19233C;
        }
    }
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-left: -275px !important;
    margin-top: -170px !important;
    .center {
        width: 100%;
        height: 157px;
        padding: 8px 16px;
        margin-bottom: 30px;
        border: 1px solid #E4E7EF;
        box-sizing: border-box;
        position: relative;
        .content {
            height: 125px;
            .ant-input-textarea {
                height: 100%;
                textarea.ant-input {
                    height: 100%;
                }
            }
            text-align: right;
        }
        .length {
            position: absolute;
            bottom: 0;
            right: 8px;
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            .gray {
                color: #BFC5D1;
            }
        }
    }
}

.amp-task-package-table {
    display: flex;
    flex-flow: row wrap;
    >div {
        width: 100%;
    }
}

.amp-task-package-title {
    // margin-top: 30px;
    margin-bottom: 16px;
}

.amp-color-red {
    color: red;
}

.amp-assets-info-item {
    display: flex;
}

.amp-w-50 {
    flex: 0 1 50%;
}

.amp-w-100 {
    flex: 0 1 100%;
}

.amp-assets-info-description {
    .to-vw(754)
}

.people-display {
    margin-top: 0 !important;
}

.amp-assets-info-item-photo {
    .amp-img {
        width: 120px;
        height: 120px;
        margin-right: 10px;
    }
}

// 任务信息
.amps-plan-task-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 86px;
    margin: 0 6px 8px 0;
    background: #fff; // border: 0.5px solid #bfc5d1;
    .task-img-body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 60px;
        margin-top: 6px;
        background: #000;
        .assets-img {
            width: 100%;
            height: 100%;
        }
    }
    .task-text {
        margin-top: 4px;
        font-weight: 300;
        font-size: 12px;
        font-family: Noto Sans SC;
        font-style: normal;
    }
    .background-invalid {
        background: rgba(0, 0, 0, 0.6);
    }
    .text-invalid {
        opacity: 0.6;
    }
}

.amp-border-no-see {
    border: 1px solid #9b9b9b;
}

.amp-border-pass {
    border: 1px solid #29c1b9;
}

.amp-border-reject {
    border: 0.5px solid #fb3152;
}

.amp-border-invalid {
    border: 1px solid #bfc5d1;
}

.amp-carousel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.amp-carousel-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.amp-carousel-modal {
    width: 935px;
    height: 736px;
    background: #000;
    color: #FFF;
    position: relative;
}

.amp-carousel-modal-top {
    width: 100%;
    padding: 30px 10px 0 24px;
    display: flex;
    justify-content: space-between;
    .amp-carousel-modal-details {
        display: flex;
        flex-direction: column;
        z-index: 1;
    }
    .amp-carousel-modal-close {
        color: #FFF;
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
}

.amp-carousel-modal-images {
    padding: 30px;
}

.amp-carousel-modal-image {
    display: block;
    max-height: 600px;
}
.amp-carousel-modal-project-id{
    position: absolute;
    right: 20px;
    bottom: 20px;
}
