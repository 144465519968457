@import "@/stylesheets/global.less";
@import "../../../stylesheets/theme";

@name: amps-typography;

.@{name} {
    display: block;
    font-family: 'Noto Sans SC', serif;
    font-style: normal;
}

.@{name}-h1 {
    #h1-styles
}

.@{name}-h2 {
    #h2-styles
}

.@{name}-h3 {
    #h3-styles
}

.@{name}-subtitle1 {
    #subtitle-styles1
}

.@{name}-subtitle2 {
    #subtitle-styles2
}

.@{name}-body1 {
    #body-styles1
}

.@{name}-body2 {
    #body-styles2
}

.@{name}-caption {
    #body-caption
}

.@{name}-weight-default {
    font-weight: 400;
}

.@{name}-weight-bold {
    font-weight: bold;
}

.@{name}-weight-500 {
    font-weight: bold;
}

.@{name}-weight-600 {
    font-weight: bold;
}

.@{name}-weight-700 {
    font-weight: bold;
}

.@{name}-inherit {
    text-align: inherit;

}

.@{name}-center {
    text-align: center;
}

.@{name}-left {
    text-align: left;
}

.@{name}-right {
    text-align: right;
}

.@{name}-noWrap {
    word-break: normal;
}

.@{name}-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.@{name}-black{
  color: @black;
}
.@{name}-primary {
    color: @primary-color
}

.@{name}-secondary {
    color: @secondary-color
}

.@{name}-primary-text {
    color: @primary-text-color
}

.@{name}-secondary-text {
    color: @secondary-text-color
}

.@{name}-secondary-text2 {
    color: @secondary-text-color2
}

.@{name}-disabled-text {
    color: @disabled-text-color
}
