.password-input {
  box-sizing: border-box;
  .to-vw(5, margin-top);
  .to-vw(5, margin-bottom);
  border-color: #d9d9d9;
}

.input-sz-l {
  .to-vw(380)!important;
  .to-vw(44, height)!important;
}

.normal-input {
  height: 40px!important;
}

.normal-input-borderNone {
  .ant-input {
    border: none !important;
  }
}

.input-sz-m {
  &:extend(.normal-input);
  .to-vw(210)!important;
  .ant-input,
  .ant-select {
    .to-vw(210) !important;
  }
}

.input-sz-ml {
  &:extend(.normal-input);
  .to-vw(330)!important;
  .ant-input,
  .ant-select {
    .to-vw(330) !important;
  }
}

.task_plan_input-sz-ml {
    &:extend(.normal-input);
    .to-vw(210)!important;
    .ant-input,
    .ant-select {
      .to-vw(210) !important;
    }
  }


.input-sz-xs {
  &:extend(.normal-input);
  .to-vw(86)!important;
  .ant-input,
  .ant-select {
    .to-vw(86) !important;
  }
}

.input-sz-s {
  &:extend(.normal-input);
  .to-vw(130)!important;
  .ant-input,
  .ant-select {
    .to-vw(130) !important;
  }
}

.input-sz-sm {
  &:extend(.normal-input);
  .to-vw(185)!important;
}

.date-sz-m {
  &:extend(.normal-input);
  .to-vw(230)!important;
}

.input-sz-long {
  &:extend(.normal-input);
  .to-vw(483)!important;
}

.select-sz-s {
  &:extend(.normal-input);
  .to-vw(130)!important;

  .ant-select {
    .to-vw(130) !important;
  }
}

.select-sz-m {
  &:extend(.normal-input);
  .to-vw(210)!important;

  .ant-select {
    .to-vw(210) !important;
  }
}

.select-sz-xs {
  &:extend(.normal-input);
  .to-vw(86)!important;

  .ant-select {
    .to-vw(86) !important;
  }
}

.required::after {
  content: "*";
  color: #ff4d4f;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  font-family: SimSun, sans-serif;
  margin-left: 3px;
  line-height: 1;
}

.labeled-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .to-vw(352);
  margin: 15px 15px 15px 0px;
}

.ant-input-disabled {
  cursor: default !important;
  color: black !important;
  background: #f5f5f5 !important;
}

.ant-select-selector {
  padding-left: 8px !important;
}

.ant-select-selection-placeholder {
  padding-left: 3px !important;
}

.se-resizing-bar {
  display: none !important;
}



.site-input-group-wrapper .site-input-split {
  background-color: #fff !important;
  &::placeholder {
    color: black !important;
  }
  border-radius: 0 !important;
}

.site-input-group-wrapper .site-input-right {
  border-left-width: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 15px;
}

.site-input-group-wrapper .site-input-left {
  border-right-width: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
    padding-left: 15px;

}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus,
.site-input-group-wrapper .site-input-right:focus-within {
  border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
  border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
  border-right-width: 1px;
}

.site-input-group-wrapper .site-input-left:hover,
.site-input-group-wrapper .site-input-left:focus,
.site-input-group-wrapper .site-input-left:active,
.site-input-group-wrapper .site-input-left:focus-within,
.site-input-group-wrapper .site-input-left:focus-visible {
  z-index: 9;
  border-right-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-left {
  z-index: 9;
  border-right-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-left:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-left:focus {
  z-index: 9;
  border-right-width: 1px;
}