.ant-modal-content {
  border-radius: 6px !important;
}

.large-form-dialog {
  .ant-modal-body {
    padding: 36px 52px 48px 52px;
    position: relative;
  }
  .ant-form-item {
    margin-bottom: 30px;
  }
}

.projectModel {
  .ant-modal-content{
    .ant-modal-body {
      padding-left: 0 !important;
      padding-right: 0 !important;
      overflow: scroll !important;
      .dialog-title {
        padding-left: 52px;
      }
      .dialog-footer {
        padding-left: 52px;
      }
    }
  }
  .dialog-content-middle {
    width: 100%;
    padding: 0px 52px;
    height: 630px;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: block;
      width: 8px; /* 纵向滚动条*/
    }
    &::-webkit-scrollbar-track {
      border: 4px solid transparent;
      background-clip: content-box; /* THIS IS IMPORTANT */
    }
    &::-webkit-scrollbar-button {
      height: 0 !important;
      width: 0 !important;
    }

    &::-webkit-scrollbar-thumb {
      background: #c8c8c8 !important;
      border-radius: 40px;
      width: 50%;
      border: 3px solid transparent;
      &:horizontal:hover {
        background: #b6b6b6 !important;
      }
    }
    .dialog-content-middle-box {
      .input-sz-m {
        width: 100% !important;
      }
    }
  }
  .dialog-footer {
    margin-top: 0 !important;
  }
}

.confidential {
  margin-bottom: 16px !important;
}

.confidential-users-list {
  div{
    display: inline-block;
    position: relative;
    width: 56px;
    height: 56px;
    span {
      z-index: 20;
      top: 0;
      right: 0;
      position: absolute;
    }
    .close-icon {
      display: none;
      position: absolute;
      top: 0;
      right: -20px;
    }
    .ant-avatar-string {
      font-size: 14px;
    }
  }
  .profile-box:hover {
    .close-icon {
      display: block;
    }
  }
  .profile-box {
    span {
      width: 48px;
      height: 48px;
      font-size: 16px;
    }
  }
}

.medium-form-dialog {
  // width: 550
  .ant-modal-body {
    padding: 60px 52px 40px 52px;
    position: relative;
  }
}
.dialog-prompt-title {
  font-size: 18px;
  line-height: 24px;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-left: 10px;
  }
}

.dialog-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 43px;
}

.dialog-footer-shadow {
  padding-top: 16px !important;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.04);
}

.dialog-title-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04)
}

.mg-review {
  margin-bottom: 0 !important;
  padding-bottom: 24px !important;
}

.dialog-heading {
  margin-bottom: 42px;
}

.skip-text {
  text-decoration: underline;
  cursor: pointer;
}

.dialog-footer {
  margin-top: 26px;
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.image-preview-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  top: 0;
  height: 100%;
  .ant-modal-content {
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
  }
  .ant-modal-body {
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: transparent !important;
  }
  .ant-modal-close-x {
    display: none !important;
  }
}

.preview-img {
  max-width: 82vw;
//   max-height: 82vh;
  object-fit: scale-down;
}

.ant-modal-wrap {
  overflow: hidden !important;
}

.ant-modal-close-x {
  font-size: 22px !important;
}

.ant-modal-body {
//   max-height: 859px;
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
  }
}

.dialog-pd{
  padding: 20px 28px 24px 28px!important;
}

.waterfall-dialog {
  .ant-modal-body {
    max-height: 100vh !important;
    overflow: hidden;
  }
  .ant-modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.waterfall-preview-image {
  background-color: #000;
  position: absolute;
  overflow: scroll;
  .to-vw(82, top);
  .to-vw(40, left);
  .to-vw(40, right);
  width: 95.834vw;
  height: 84.815vh;
  padding-top: 8px;
  .waterfall-preview-box {
    height: 15.625vw;
    margin-bottom: 0.416vw;
    .render-item {
      height: 15.625vw;
      overflow: hidden;
      text-align: center;
      background-color: #0F0F0F;
      display: inline-block;
      margin-right: 0.416vw;
      .ant-image {
        width: 100%;
        height: 100%;
      }
      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
    :last-child {
      margin-right: 0;
    }
  }
} &::-webkit-scrollbar {
  display: none;
}
