.task-card {
    min-width: 180px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    height: fit-content;
    cursor: pointer;
    padding: 14px 0;
}

.task-card-content {
    padding: 0 12px;
    box-sizing: border-box;
}

.task-list {
    min-width: 320px;
    background: #f4f5f7;
    .to-vw(294);
    border-radius: 10px;
    margin-right: 20px;
    padding: 0px 10px 0px 10px;
}

.task-list-content {
    overflow-y: auto;
    overflow-x: hidden;
    .to-vh(790);
}

.task-list-title {
    background: #f4f5f7;
    .to-vh(42);
    padding: 16px 6px 8px 6px;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 1000;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-card-head {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    .iconAB {
        width: 28px;
        height: 14px;
        display: inline-block;
        span {
            width: 100%;
            height: 100%;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .amp-task-card-bd {
        height: 20px;
        line-height: 20px;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
        padding: 0 7px;
        font-size: 12px;
        color: #595959;
    }
}

.toggleIconAB {
    cursor: pointer;
    width: 43px;
    height: 22px;
    display: inline-block;
    span {
        width: 100%;
        height: 100%;
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.task-card-people {
    display: flex;
    flex-flow: row wrap;
}

.task-card-people-section {
    display: flex;
    align-items: center;
    margin-right: 12px;
    margin-top: 8px;
}

.task-card-img {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    width: fit-content;
    img {
        object-fit: scale-down;
        background-color: black;
        border-radius: 2px;
    }
}

.task-card-title {
    font-weight: 700;
    font-size: 16px;
    margin-top: 8px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.task-card-comment {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.spin-height {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spin-large {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.task-card-status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.task-status-display {
    height: 22px;
    font-size: 13px;
    line-height: 22px; // font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: 3px 6px;
    box-sizing: border-box;
}

.task-board {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        display: block;
        width: 4px;
        /* 纵向滚动条*/
        height: 8px;
        /* 横向滚动条 */
    }
    &::-webkit-scrollbar-track {
        border: 4px solid transparent;
        background-clip: content-box;
        /* THIS IS IMPORTANT */
    }
    &::-webkit-scrollbar-button {
        height: 0 !important;
        width: 0 !important;
    }
    &::-webkit-scrollbar-thumb {
        background: #c8c8c8 !important;
        border-radius: 40px;
        width: 50%;
        border: 3px solid transparent;
        &:horizontal:hover {
            background: #b6b6b6 !important;
        }
    }
}

.task-board> :last-child {
    margin-right: 0 !important;
}

.amp-drawer-title-fixed {
    position: fixed;
    width: 100%;
    background: #FFF;
    z-index: 999;
    padding-top: 70px;
    margin-top: -78px;
}
