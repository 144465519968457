@import "@/stylesheets/global.less";
/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-max-type */
.amps-handover-page {
    .ant-form-item-label > label {
        font-weight: 500;
    }

    .ant-form-item-label > label::after {
        content: "";
    }

    .ant-form-item-label {
        display: flex;
        flex: 0 0 1 !important;
    }

    .amp-business-data-from-page {
        display: flex;
        align-items: normal;
        justify-content: space-between;
        margin-top: 20px;
    }

    .amp-business-data-button {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: -1px;
        padding-bottom: 20px;

        .amp-btn-sz-s {
            width: 100px;
            height: 40px;
        }
    }

    .amp-business-data-form {
        display: flex;
        flex-flow: row wrap;

        .amp-input-sz-s {
            .to-vw(120)!important;

            min-width: 100px;
            max-width: 120px !important;
            height: 40px !important;
            margin-right: 12px;
            border-radius: 2px;
        }

        .amp-input-sz-l {
            .to-vw(210)!important;

            min-width: 180px;
            max-width: 210px !important;
            height: 40px !important;
            margin-right: 12px;
        }

        .amp-btn-sz-s {
            width: 100px;
            height: 40px;
        }

        .amp-blue-border {
            margin-right: 10px;
            color: #29c1b9 !important;
            border: 1px solid #29c1b9 !important;
            border-radius: 4px;
        }
    }

    .amp-handover-status {
        height: 24px;
        padding: 0 9px;
        font-size: 14px;
        line-height: 24px;
        border-radius: 6px;
    }

    .amp-no-analytical {
        color: #8c8c8c;
        border: 1px solid #8c8c8c;
    }

    .amp-analytical-ing {
        color: #595959;
        border: 1px solid #595959;
    }

    .amp-finish {
        color: #29c1b9;
        border: 1px solid #29c1b9;
    }

    .amp-fail {
        color: #bfbfbf;
        border: 1px solid #bfbfbf;
    }

    .amp-line-clamp-3 {
        display: -webkit-box;
        display: -moz-box;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
    }

    .amp-mg-l-30 {
        margin-left: 30px;
    }
}
