@import "@/stylesheets/global.less";
/* stylelint-disable selector-max-type */

/* stylelint-disable declaration-no-important */

.amp-mg-t-20 {
    margin-top: 20px;
}

.amp-statistical-from {
    display: flex;
    justify-content: space-between;
}

.amps-collection-data {
    padding-right: 24px;
    padding-left: 24px;
    // TODO: 临时
    /* stylelint-disable-next-line selector-class-pattern */
    .drawer-close-icon-active {
        top: 20px !important;
    }
}

.amp-analytical-data-form {
    display: flex;
    flex-flow: row wrap;

    .amp-input-sz-l {
        .to-vw(210)!important;

        min-width: 180px;
        max-width: 210px !important;
        height: 40px !important;
        margin-right: 12px;
    }

    .amp-ranger-picker-l {
        .to-vw(280)!important;

        min-width: 220px;
        max-width: 280px !important;
        height: 40px !important;
        margin-right: 12px;
    }

    .ant-row {
        flex-flow: row wrap;

        .ant-form-item-label {
            display: flex;
            align-items: center;
            font-weight: 700px;
        }
    }

    .amp-btn-sz-s {
        width: 100px;
        height: 40px;
    }

    .amp-blue-border {
        margin-right: 10px;
        color: #29c1b9 !important;
        border: 1px solid #29c1b9 !important;
        border-radius: 4px;
    }

    .ant-form-item-label > label {
        font-weight: 500;
    }

    .ant-form-item-label > label::after {
        content: "";
    }
}

.amp-table-summary-no-thead {
    margin-top: 10px;

    .ant-table-wrapper {
        box-shadow: 0 -2px 12px 0 #cccccc6b;
    }
}
// 表格操作按钮
.amp-blue-btn {
    color: #29c1b9;
}
