@import "@/stylesheets/global.less";
.associate-modal {
    position: relative;

    .ant-modal-content {
        .ant-modal-body {
            padding: 16px 0 15px 0 !important;
            min-height: 446px;
            overflow: hidden !important;
        }
    }
}

.associate-modal-content {
    min-height: 400px;
    overflow-y: scroll;
    padding: 30px 32px 0px;

}

.associatedFooter {
    width: 100%;
    padding: 10px 0 10px 20px;
    z-index: 999;
    background: #FFF;
    position: absolute;
    bottom: 10px;
    left: 0;
}

.associated-content {
    min-height: 300px;
    margin-bottom: 20px;
}

.associate-form {
    .ant-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        >.ant-form-item {
            display: flex;
            flex: 0 1 50%;
            justify-content: flex-start;
        }

        .ant-form-item-label {
            min-width: 90px;

            text-align: left;

            >label::after {
                content: ""
            }
        }
    }
}

.upload-form-item {
    display: flex !important;
    flex-direction: column !important;

    .ant-form-item-label {
        text-align: left;
    }
}

.associate-input-sz-ml {
    &:extend(.normal-input);
    .to-vw(230) !important;

    .ant-input,
    .ant-select {
        .to-vw(230) !important;
    }
}

.associated-content-no-data {
    margin: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.associated-content-no-data-span {
    color: #8B94A5;
}

.associated-content {
    width: 100%;

    &-form {
        &-item {
            display: flex;
            align-items: center;
            margin-bottom: 39px;

            &-label {
                font-size: 14px;
                color: #272727;
                min-width: 90px;
            }
        }
    }

    &-title {
        margin-bottom: 24px;
        color: #272727;
        font-size: 14px;
    }

    &-scroll {
        display: flex;
        flex-wrap: wrap;
        max-height: 356px;
        overflow-y: scroll;

        &-nodata {
            flex: 0 0 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-img {
                width: 100px;
                height: 100px
            }

            &-text {}
        }

        &-loading {
            margin-top: 80px;
            display: flex;
            flex: 0 0 100%;
            width: 100%;
            justify-content: center
        }

        &-footer-loading {
            display: flex;
            flex: 0 0 100%;
            width: 100%;
            justify-content: center
        }
    }
}

.amp-associated-content-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .amp-associated-content-list-images {
        width: 60px;
        height: 60px;
        display: block;
        margin-right: 20px;
    }

}

.upload-form-item {
    flex: 0 1 100% !important;
}

.amp-reSelect {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
}

.amps-plan-task-img-no-select {
    background: rgba(0, 0, 0, 0.4);
}

.amps-plan-task-img {
    &:hover {
        background: rgba(0, 0, 0, 0.4);
    }
}

.amp-border-no-see {
    border: 1px solid #9b9b9b;
}

.amp-border-pass {
    border: 1px solid #29c1b9;
}

.amp-border-reject {
    border: 0.5px solid #fb3152;
}

.amp-border-invalid {
    border: 1px solid #bfc5d1;
}

.task-img-body {
    width: 68px;
    height: 58px;

    .ant-image {
        height: 100%;

        img {
            height: 100%;
        }
    }
}

// 任务信息
.amp-associated-img {
    width: 80px;
    height: 86px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &:hover {
        background: rgba(0, 0, 0, 0.4);
    }

    .task-img-body {
        width: 68px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000;
        margin-top: 6px;

        .assets-img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    .task-text {
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        margin-top: 4px;
    }

    .amp-associated-selected {
        position: absolute;
        top: 23px;
        left: 27px;
    }
}
