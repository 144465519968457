.btn-sz-m {
  width: 120px;
  height: 40px;
}

.btn-sz-s {
  width: 100px;
  height: 40px;
}

.btn-noBorderWidth {
  border-width: 0px !important;
  background-color: #E8E8E8 !important;
}

.btn-abled {
  background-color: #29C1B9 !important;
  color: #fff !important;
}

.ant-btn {
  border-radius: 4px !important;
}

.span-btn {
  width: 100%;
  height: 40px;
}

.ant-btn-icon-only {
  width: 40px;
  height: 40px;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #b6b6b6 !important;
  &:hover,
  &:active,
  &:focus {
    background-color: white !important;
    border-color: #b6b6b6 !important;
  }
}

.blue-btn {
  color: #29c1b9 !important;
  border-color: #29c1b9 !important;
}

.amp-button-primary{
    min-width: 80px;
    max-width: 100px;
    height: 40px;
}
.amp-button-default{
    width: 80px;
    min-width: 80px;
    max-width: 100px;
    height: 40px;
}
