@import "@/stylesheets/global.less";
.maps-scroll-container{
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    &-nodata{
        padding: 100px;
        width: 100%;
        text-align: center;
    }
    &-no-data{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
    &-loading{
        padding: 10px;
        flex: 1;
        display: flex;
        justify-content: center;
    }
    &-error{
        padding: 10px;
        flex: 1;
        display: flex;
        justify-content: center;
    }
    &-nodata-error{
        padding: 50px;
        flex: 1;
        display: flex;
        justify-content: center;
    }
    &-end{
        padding: 10px;
        flex: 1;
        display: flex;
        justify-content: center;
    }
}
