@import "@/stylesheets/global.less";
@import "../stylesheets/theme";

.amps-side-bar-layout-menu-up {
    padding-left: 257px;

    .menu {
        width: 257px;
    }

    .menu-icon-collapsed {
        right: -20px;

    }
}

.amps-side-bar-layout-menu-down {
    padding-left: 50px;

    .menu {
        width: 50px;
    }

    .menu-icon-collapsed {
        right: -42px;
    }
}

.amps-side-bar-layout {
    position: relative;
    margin: -12px -50px -32px;

    .ant-menu {
        color: @black;
    }

    .ant-tabs-tab {
        margin-left: 40px;
    }

    .menu-icon-collapsed {
        position: absolute;
        top: 50%;
        margin-top: -70px;
        cursor: pointer;
    }

    .menu-text {
        font-size: 14px;
        font-weight: 500;
    }

    .side-bar-layout-menu-box {
        z-index: 99;
        position: fixed;
        top: 60px;
        left: 0;
        //   overflow: hidden;
        height: calc(100% - 60px);

        .menu {
            height: 100%;
            overflow: auto;
        }
    }

    .side-bar-layout-container {
        border: 1px solid red;
        position: relative;
    }

    .page-container {
        padding-left: 24px;
        padding-right: 24px;
    }

    .nav-layout-container {
        overflow: auto;
    }
}
