@import "@/stylesheets/global.less";
.amp-upload-custom-sign {


    .amp-upload-custom-preview {
        display: flex;
        flex-wrap: wrap;
    }

    .ant-upload-disabled .amp-upload-custom-upload-button {
        cursor: not-allowed;
    }

    .amp-upload-custom-upload-item {
        width: 104px;
        height: 104px;
        margin: 0 8px 8px 0;
        vertical-align: top;
        padding: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;

        &.error {
            border-color: #ff4d4f;
        }
    }

    .amp-upload-preview {
        position: relative;
        height: 100%;
        width: 100%;


    }

    .amp-upload-hover-preview {
        cursor: pointer;

        &:hover {

            .amp-upload-preview-mask,
            .amp-upload-preview-main {
                opacity: 1;
            }
        }
    }

    .amp-upload-preview-img {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .amp-upload-preview-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        opacity: 0;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, .5);
        transition: opacity 0.3s;
    }

    .amp-upload-preview-main {
        position: absolute;
        opacity: 0;
        top: 5px;
        right: 5px;
        border-radius: 4px;
        background-color: #29C1B9;
        font-size: 12px;
        color: #fff;
        line-height: 20px;
        padding: 0 8px;
        cursor: pointer;

        &.current {
            opacity: 1;
        }
    }

    .amp-upload-preview-error {
        position: absolute;
        top: 5px;
        right: 0;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #ff4d4f;
        line-height: 20px;
    }

    .amp-upload-preview-uploading {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .amp-upload-preview-mask-btn {
        width: 24px;
        color: #fff;
        font-size: 16px;
        text-align: center;
    }

    .amp-upload-custom-upload-button {
        width: 104px;
        height: 104px;
        margin: 0 8px 8px 0;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        transition: border-color 0.3s;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        &:hover {
            border-color: #29C1B9;
        }
    }
}
