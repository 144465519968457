@import "@/stylesheets/global.less";
@import "../../stylesheets/theme";

.amps-task-package {
    .ant-modal-header {
        .ant-modal-title {
            #h3-styles
        }
    }

    .ant-select{
        color: @primary-text-color !important;
    }

    .ant-input{
        color: @primary-text-color !important;
    }

    .ant-table-cell {
        padding: 10px 4px;
    }
    .ant-table-thead > tr > th{
        color: @primary-text-color !important;
        font-weight: 300;
    }

    .flex-align-center {
        display: flex;
        align-items: center
    }

    .mt-24 {
        margin-top: 24px
    }

    .mt-14 {
        margin-top: 14px
    }

    .ml-10 {
        margin-left: 10px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mr-7 {
        margin-right: 7px;
    }

    .ant-modal-body {
        padding: 0;
        min-height: 500px;
        height: 85VH;
        overflow: auto;
        position: relative;
    }

    &-content {
        resize: both;
        display: flex;
        align-items: stretch;
        height: 100%;
        position: relative;
        background-color: @primary-background-color;

        .task-tags {
            display: flex;
            align-items: center;

            .task-tag {
                margin-right: 10px;
                border-radius: 2px;
                padding: 1px 5px;
                line-height: 18px;
                color: @secondary-text-color;
            }
        }

        .list-content {
            box-sizing: border-box;
            padding: 24px 0;
            flex: 0 0 366px;
            display: flex;
            flex-direction: column;
            position: relative;
            background-color: @secondary-background-color;


            &-header {
                flex: 0;
                padding: 0 32px;
                display: flex;
                flex-direction: column;
                align-items: stretch;

                &-text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }


                &-tooltip-list {
                    display: flex;
                }

                &-tooltip-item {
                    margin: 0 7px;
                    font-size: 14px;
                    line-height: 20px;

                    span {
                        display: inline-block;
                        margin-right: 4px;
                        width: 10px;
                        height: 10px;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                }

                &-btn {
                    margin-top: 30px;
                    background-color: transparent;
                    border: 1px dashed #BFC5D1;
                    box-sizing: border-box;

                    &:hover {
                        background-color: transparent;

                    }
                }

                &-btn-action {
                    border-color: @primary-color;
                }
            }

            &-scroll {
                flex: 1;
                margin-top: 14px;
                position: relative;
                height: 100%;
                flex-direction: column;
                flex-wrap: nowrap;

                &::-webkit-scrollbar {
                    display: inline !important;
                }

                &-end, &-error {
                    flex: inherit;
                    width: inherit;
                }
            }

            .task-list-item {
                cursor: pointer;
                width: 300px;
                margin: 6px 32px;
                background-color: @primary-background-color;
                border-radius: 6px;
                box-shadow: 0 10px 20px @border-color2;
                border: 1px solid @border-color2;
                padding: 16px 12px;

                &-head {
                    display: flex;
                    justify-content: space-between;
                }

                &-text {
                    display: flex;
                    align-items: center;
                    #h3-styles;
                }

                &-text-icon {
                    margin-right: 7px;
                }

                &-text-icon {
                    margin-right: 7px;
                }


                &-icon {
                    font-size: 20px
                }

                &-title {
                    margin-top: 14px;
                    display: flex;
                }

                &-title1 {
                    flex: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;

                }

                &-images-row {
                    margin-top: 14px;
                    display: flex;
                    overflow: hidden;
                    box-sizing: border-box;
                    margin-left: -2px;
                    margin-right: -2px;
                    flex-wrap: wrap;
                }

                &-images-col {
                    flex: 0 0 55px;
                    overflow: hidden;
                    position: relative;
                    height: 55px;
                    display: flex;
                    padding: 2px;
                    box-sizing: border-box;

                    &-box {
                        background-color: @primary-text-color;
                        flex: 1;
                        border-radius: 2px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .ant-image {
                            flex: 1;
                            height: 100%;
                            position: relative;
                            display: flex;
                            align-items: center;

                            .ant-image-mask {
                                zoom: 0.6;
                            }
                        }

                        &-image {
                            max-height: 100%;
                            max-width: 100%;
                        }

                        &-end {
                            pointer-events: none;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: fade(@primary-text-color, 70);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            i {
                                width: 4px;
                                height: 4px;
                                background: @secondary-background-color;
                                border-radius: 2px;
                                margin: 0 2px;
                            }
                        }
                    }
                }

                &-desc {
                    margin-top: 14px;
                    font-size: 14px;
                    font-weight: 300;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:hover {
                    border-color: @primary-color;

                }
            }


            .task-list-item-active {
                border-color: @primary-color;
            }
        }

        .detail-body {
            height: 100%;
            flex: 1;
            position: relative;
            padding: 24px 32px 0;
            display: flex;
            overflow: hidden;
            flex-direction: column;

            &-main {
                flex: 1;
                height: 100%;
                overflow: auto;
                position: relative;
                padding-bottom: 50px;

                &::-webkit-scrollbar {
                    display: inline !important;
                }

                &-content {
                    width: 898px;
                    flex: 1;
                    position: relative;
                }
            }


            &-form {
                position: relative;

                &-title {
                    margin-bottom: 35px;
                }

                .ant-form-item-label {
                    label {
                        #subtitle-styles2;
                        color: @primary-text-color !important;
                        font-weight: 300;
                    }
                }

                .journey-form-item {
                    margin: 0 !important;
                    height: 40px !important;

                    .ant-form-item-explain-error {
                        color: #ff4d4f;
                        position: absolute;
                        bottom: -15px;
                        font-size: 12px;
                        min-height: 12px;
                    }
                }
            }

            &-footer {
                margin: 0 -32px;
                box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
                padding: 20px 32px;
                flex: 0;
            }

            .col-xs {
                position: relative;
                min-height: 1px;
                display: block;
                flex: 0 0 100%;
                min-width: 0;
                max-width: calc(100% - 20px);
            }

            .col-sm {
                position: relative;
                min-height: 1px;
                display: block;
                flex: 0 0 50%;
                min-width: 0;
                max-width: calc(50% - 20px);
            }

        }
    }

    .mission-info {
        min-height: 150px;
        max-height: 350px;
        overflow: auto;
    }

    .mission-info-form {
        padding: 5px 0;

        &-statics {
            display: flex;
            align-items: center;
        }

        &-desc {
            margin-right: 24px;
            display: flex;
            color: #19233C;
            font-size: 14px;
        }

        &-value {
            font-weight: 300;
            margin-right: 5px;
        }

        &-label {
            font-weight: 400;
        }
    }

    .desc-row {
        display: flex;
        margin-top: 16px;
        width: 100%;

        .desc-col {
            min-width: 242px;
            display: flex;

            .desc-label {
                min-width: 86px;
                flex: 0
            }

            .desc-value {
                flex: 1;
            }
        }

        .desc-table {
            margin-top: 12px;
            width: 100%;
        }

        .desc-col-column {
            flex: 1;
            flex-direction: column
        }
    }

    .label-required {
        color: #ff0000;
    }
}

.batch-title {
    display: flex;
    align-items: center;

    p {
        padding-right: 20px;
        position: relative;
        font-weight: 600;

        &::before {
            content: "";
            width: 2px;
            height: 13px;
            position: absolute;
            right: 0;
            top: 6px;
            background-color: @border-color;
        }
    }

    a {
        padding: 0 20px;
        display: flex;
        align-items: center;
    }
}

.amps-collapse {
    &-child {
        border-bottom: 1px solid @border-color;

        &:last-child {
            border: none;
        }
    }

    .amps-panel {
        display: block;

        &-title {
            cursor: pointer;
            padding-bottom: 8px;

            &-icon {
                margin-left: 7px;

                svg {
                    transition: transform 0.2s;
                }
            }
        }

        &-content {
            padding: 15px;
        }

        &-content-open {
            //height: 100px;
        }

        &-content-close {
            height: 0;
        }
    }
}
