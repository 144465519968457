@import "@/stylesheets/global.less";
@name: amps-drawer;

.@{name}{
    z-index: 1000;
    .ant-drawer-title{
        font-size: 18px;
    }
    .ant-drawer-wrapper-body{
        .ant-drawer-header {
            padding: 14px 32px;
        }
        .ant-drawer-body{
            padding: 24px 32px 36px 32px;
            position: relative;
            width: auto;
            height: auto;
        }
        .ant-drawer-footer{
            padding: 16px 32px;
        }
    }
}
