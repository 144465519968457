@import "@/stylesheets/global.less";
.amps-task-details {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    .task-planning{
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        .task-planning-content {
            width: 904px;
            bottom: 0;
            right: 0;
            padding: 20px 20px 80px 20px;
            >div {
                height: 100%;
                width: 100%;
            }
        }
    }
    .task-planning::-webkit-scrollbar {
        width: 6px;
        display: block !important;
      }
}
