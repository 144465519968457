@import "@/stylesheets/global.less";
.amp-map-search {
    .amp-map-search-select {
        margin-bottom: 8px;
    }

    .ant-select,
    .ant-select-selector {
        height: auto;
    }

    .ant-select-selection-overflow {
        flex-wrap: wrap;
    }

    .amp-search-select-option {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .amp-option-name {
        margin-right: 16px;
    }

    .amp-option-district {
        font-size: 12px;
        color: #BFBFBF,
    }
}
