@import "@/stylesheets/global.less";
.amps-trip-edit {
    box-sizing: border-box;
    width: 100%;
    line-height: 40px;
    height: 40px;
    table-layout: fixed;
    background: #f4f4f4;
    border: 1px solid #f4f4f4;

    .ant-picker {
        width: 100%;
    }

    .amp-edit-td {
        height: 40px;
        padding: 0px 7px;
        // vertical-align: middle;
    }

    .ant-form-item {
        height: 100%;
        margin-bottom: 0;
    }

    .amp-edit-col1 {
        width: 26.86%;
        padding-left: 20px;
    }

    .amp-edit-col2 {
        width: 11.91%;
    }

    .amp-edit-col3 {
        width: 17.14%;
    }

    .amp-edit-col4 {
        width: 11.91%;
    }

    .amp-edit-col5{

    }

    .amp-edit-col6 {
        width: 90px;
        line-height: 56px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input {
        height: 40px;
        line-height: 40px;
    }

    /* stylelint-disable-next-line selector-max-class */
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 40px;
    }

    .amp-add-icon {
        font-size: 40px;
        vertical-align: middle;
        cursor: pointer;
    }
}
