@import "@/stylesheets/global.less";
.amps-topic-details-modal {
    top: 60px;
    height: 90%;
    margin: 0 50px;
    overflow: hidden;

    .ant-modal-header {
        height: 64px;
        overflow: hidden;
    }

    .ant-modal-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .ant-modal-body {
        // height: calc(100% - 134px);
        height: 100%;
        // padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
    }

    .ant-modal-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70px;
        padding-top: 0;
        line-height: 70px;
        background: #fff;
    }
}
