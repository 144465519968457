@import "@/stylesheets/global.less";
@import "../../stylesheets/theme";

.amp-pool {
    min-height: 500px;
    overflow: auto;
    position: relative;
    .amp-pool-title {
        .pool-title {
            color: #000;
            font-family: Source Han Sans SC VF;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .ant-modal-body {
        padding: 0 !important;
        height: 85VH;
    }

    .receive-content {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;

        .receive-header {
            display: flex;
            flex-direction: column;
            .toggle {
                font-family: Source Han Sans SC VF;
            }
        }

        .title-form-field {
            color: #0E1114;
            font-family: Source Han Sans SC VF;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
        }

        .receive-body {
            height: 100%;
            flex: 1;
            overflow: hidden;

            .receive-body-scroll {
                flex: 1;
                position: relative;
                height: 100%;
                flex-direction: column;
                flex-wrap: nowrap;

                &::-webkit-scrollbar {
                    display: inline !important;
                }
            }
        }


        .receive-footer {
            flex: 0;
        }

        .pool-item {
            padding: 14px;
            background: @primary-background-color;
            border: 1px solid @border-color;
            box-shadow: 0 3px 6px @primary-shadow-color;
            border-radius: 10px;
            overflow: hidden;

            &:hover {
                border-color: @primary-color;
            }

            .images {
                display: flex;
                flex-wrap: nowrap;

                .image {
                    background: #000;
                }

                .disabled-bg {
                    background: @disabled-text-color;
                }
            }

            .title-text {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .id-title-font {
                color: #000;
                font-family: Noto Sans SC;
                font-size: 14px;
                font-weight: 300;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
            }

            .item-grade {
                font-family: Noto Sans SC;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #000;
            }

            .text-no-select {
                user-select: none;
            }

            // 拆分的样式交互
            .split-box-select {
                .split-box-on {
                    display: none;
                }

                .split-box-off {
                    display: block;
                }
            }

            .split-box {
                .split-box-on {
                    display: block;
                }

                .split-box-off {
                    display: none;
                }

                &:hover {
                    .split-box-on {
                        display: none;
                    }

                    .split-box-off {
                        display: block;
                    }
                }
            }

        }

        // 选择的样式交互
        .select-box-disabled {
            .select-box-on {
                display: none;
            }

            .select-box-off {
                display: none;
            }
        }

        .select-box-select {
            .select-box-on {
                display: none;
            }

            .select-box-off {
                display: block;
            }
        }

        .select-box {
            .select-box-on {
                display: none;
            }

            .select-box-off {
                display: none;
            }

            &:hover {
                .select-box-on {
                    display: block;
                }

                .select-box-off {
                    display: none;
                }
            }
        }

        // 是否公开的样式交互
        // 不公开
        @open-0: {
            .open-box-0 {
                display: block;
            }

            .open-box-1 {
                display: none;
            }
        }
        // 公开
        @open-1: {
            .open-box-0 {
                display: none;
            }

            .open-box-1 {
                display: block;
            }
        }
        // 多选模式
        .disable-select{
            .open-box-0 {
                display: none;
            }

            .open-box-1 {
                display: none;
            }
            &:hover{
                border-color: @border-color;
            }
        }
        // 不公开选中
        .open-box-0-select {
            @open-0();
        }
        // 公开选中
        .open-box-1-select {
            @open-1();
        }
        // 不公开没有选中的hover
        .open-box-default-0 {
            .open-box-0 {
                display: none;
            }

            .open-box-1 {
                display: none;
            }

            &:hover {
                @open-0();
            }
        }
        // 公开没有选中的hover
        .open-box-default-1 {
            .open-box-0 {
                display: none;
            }

            .open-box-1 {
                display: none;
            }

            &:hover {
                @open-1();
            }
        }
        /*------------------*/

        // 作废选中状态
        .destroy-select{
            border-color: @primary-color;
            &:hover {
                border-color: @primary-color;
            }
        }
        .reset-button {
            line-height: 1.5715;
            height: 32px !important;
            padding: 4px 15px !important;
            width: auto !important;
            min-width: auto !important;
        }

        .reset-button-select {
            border-color: @primary-color !important;
            color: @primary-color !important;
        }

        .pool-item-disabled {
            background: @secondary-background-color;

            &:hover {
                border-color: @border-color;
            }
        }

        .category {
            padding: @spacer*0.5 @spacer*1.5;
            border-radius: 20px;
            border: 1px solid @border-color;
            color: @primary-text-color;
            background-color: transparent;
            cursor: pointer;
            font-family: Source Han Sans SC VF;
        }

        .category-select {
            color: @secondary-text-color;
            background-color: @primary-color;
        }
        .produce-info-box{
            position: relative;
            padding-left: 40px;
            &:before{
                content: "";
                position: absolute;
                left: 0;
                top: 8px;
                height: 18px;
                width: 1px;
                background-color: @primary-text-color;
            }
        }
    }

}
