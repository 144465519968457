@import "@/stylesheets/global.less";
.amps-image {
    background: #000;
    z-index: 0;
    position: relative;
    overflow: hidden;

    &-mask {
        position: absolute;
        padding: 4px;
        display: flex;
        top: 0;
        z-index: 5;
        left: 0;
        width: 100%;
        justify-content: space-between;
        background-color: #000;
        &-item {
            flex: 1;
            justify-content: center;
            position: relative;
            padding: 0 2px;
            display:flex;
            &::after {
                content: "''";
                right: 0;
                top: 2px;
                position: absolute;
                height: 17px;
                width: 1px;
                overflow: hidden;
                box-sizing: border-box;
                background-color: #ffffff50;
            }

            &:last-child::after {
                display: none;
            }

            &-span {
                height: 25px;
                flex: 1;
                min-width: 25px;
                max-width: 60px;
                overflow: hidden;
                cursor: pointer;
                display: flex;
                background-color: transparent;
                align-items: center;
                justify-content: center;
                padding: 4px;
                border-radius: 2px;
                &:hover {
                    background-color: #ffffff15;
                }
            }

        }
    }

    &-footer {
        user-select: none;
        z-index: 1;
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        padding: 4px;
        align-items: center;

        &-score {
            background: rgba(255, 255, 255, 0.15);
            border: 1px solid rgba(255, 255, 255, 0.3);
            box-sizing: border-box;
            border-radius: 2px;
            flex: 0 0 28px;
            height: 28px;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            text-align: center;
            color: #fff;
            margin-right: 6px;
        }

        &-title {
            flex: 1;
            line-height: 18px;
            font-size: 14px;
            color: #fff;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }

    &-disabled {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 4;
        width: 100%;
        height: 100%;
        cursor: not-allowed;
        background: hsla(0, 0%, 100%, .4);
    }

    &-checked {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: #00000040;
        display: flex;
        align-items: center;
        justify-content: center;

    }

}

.amp-status-1 {
    border: 1px solid #9b9b9b;
}

.amp-status-2 {
    border: 1px solid #29c1b9;
}

.amp-status-3 {
    border: 0.5px solid #fb3152;
}

.amp-status-4 {
    border: 1px solid #bfc5d1;
}
