@import "@/stylesheets/global.less";
.amps_fall_item{
    overflow: hidden;
    display: flex;
    padding: 3px;
    align-items: stretch;
    height: 15VW;
    position: relative;
    &_box{
        flex: 1;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        background-color: #0f0f0f;
        display: flex;
        flex-direction: column;
        align-items: center;
        &_img_box{
            width: 100%;
            flex: 1;
            height: 100%;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            display: flex;
            position: relative;
            .ant-image {
                height: 100%;
                width: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }

        }

        &_text{
            position: absolute;
            display: none;
            opacity: 0;
            transition: all  .2s ;
            bottom: 5px;
            align-items: center;
            width: 100%;
            p{
                overflow: hidden;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #484848;
                font-size: 12px;
                flex: 1;
                padding: 0 15px;
            }
            p:last-child{
                width: 110px;
                text-align: right;
                float: right;
            }
            p:hover {
                color: #29C1B9;
                cursor: pointer;
            }
        }

        &:hover{
            border: 1px solid #3C3C3C;
            .amps_fall_item_box_text{
                opacity: 1;
                display: flex;
            }
        }
    }
    .amp-split-icon{
        font-size: 20px;
        position: absolute;
        top: 15px;
        right: 12px;
    }
}
