.ant-drawer-body {
    padding: 40px 40px 150px 48px;
    position: absolute;
    .to-vh(80, top);
    width: 100%;
}

.drawer-close-icon-active {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #ffffff;
    box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    font-size: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    z-index: 301 !important;
    top: 78px;
    transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-drawer {
    z-index: 300 !important;
}

.drawer-close-icon-inactive {
    opacity: 0;
    position: absolute;
    top: 10px;
    transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.drawer-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 20px 60px 20px 50px;
    background-color: white;
    height: 80px;
    box-sizing: border-box;
    border-top: none !important;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
    z-index: 50;
}

.ant-drawer-bottom {
    .ant-drawer-body {
        padding: 30px 20px 20px 60px;
        position: absolute;
        top: inherit;
        width: 100%;
        height: 100%;
    }
}

.second-drawers-form {
    margin-bottom: 0px !important;
}

.ant-form-item .ant-input-textarea-show-count::after {
    right: 10px;
}

.bottom-drawer-visible {
    .ant-drawer-bottom {
        z-index: 500 !important;
    }
}

.bottom-drawer-disappear {
    .ant-drawer-bottom {
        z-index: 0 !important;
    }
}

.ant-drawer-footer {
    z-index: 50 !important;
}

.amp-in-drawer {
    padding-bottom: 60px;

    .amp-in-drawer-button {
        width: 100%;
        height: 80px;
        box-shadow: 0px -1px 10px rgb(0 0 0 / 10%);
        background: #FFF;
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 20px 60px;
    }
}

.amp-drawer-score {
    display: flex;
    align-items: flex-start;

    .amp-drawer-score-stepper {
        display: flex;
        justify-content: space-between;
        .to-vw(210)
    }

    .amp-score-input {
        .to-vw(100);
        text-align: center;
    }

    .amp-score-button {
        color: #C4C4C4;
        border-color: #D9D9D9;

        &:hover {
            color: #4D4D4D;
        }

        &:disabled {
            color: #C4C4C4;
        }
    }

    .amp-drawer-score-label {
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .amp-drawer-score-label-item {
        height: 40px;
        line-height: 40px;
        width: 40px;
        background-color: #F4F5F7;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        margin: 0 8px 8px 0;

        &:hover,
        &.current {
            background-color: #F3FFFD;
        }
    }
}

.amp-drawer-opinion {
    .amp-drawer-opinion-quick {
        display: flex;
        align-items: flex-start;
    }

    .amp-drawer-opinion-label {
        flex: 1;
        display: flex;
        min-width: 0px
    }

    .amp-drawer-opinion-taglist {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        min-width: 0px
    }

    .amp-drawer-opinion-key {
        margin: 0 10px 10px 0;
        max-width: 31%;
        min-height: 24px;
        line-height: 24px;
        background-color: #F4F5F7;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 10px;

        &:hover {
            background-color: #E7FFFB;
        }
    }

    .amp-drawer-opinion-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
    }

    .amp-drawer-opinion-delete {
        margin-left: 5px;

        .icon-close {
            padding-left: 5px;
            border-left: 1px solid #E5E5E5;
        }
    }

    .amp-drawer-opinion-button {
        min-width: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .amp-opinion-button-icon {
        min-width: 50px;
    }

    .amp-opinion-button {
        color: #E5E5E5;

        &:hover {
            color: #4D4D4D;
        }
    }

    .amp-drawer-opinion-add {
        min-width: 120px;
        justify-content: center;
    }

    .amp-drawer-opinion-input {
        border: none;
        line-height: 24px;
        background: none;
        box-shadow: none;
        min-height: 24px;
        flex: 1;
        padding: 0;

        &:focus {
            border: none;
        }
    }
}

.amp-split-tasks {
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-flow: column wrap;
    padding: 15px 20px;

    .amp-split-task {
        width: 100%;
        display: flex;
        flex-flow: column wrap;

        .ant-divider-horizontal {
            margin-top: 0;
        }
    }

    .amp-split-task-item {
        width: 50%;
        .ant-form-item {
            height: 40px;
            .input-sz-m {
                width: 90% !important;
                .ant-select {
                    width: 100% !important;
                }
            }
        }
    }
}

.amp-split-task-w-100 {
    width: 100%;

    .ant-form-item-label {
        font-weight: 500;

        >label::after {
            content: ""
        }
    }
}

.amp-refresh-tip {
    display: inline-block;
    padding: 0px 10px;
    margin-left: 10px;
    background-color: #FFF7E6;
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    font-weight: 300;
}

.amp-process-asset-size{
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 20px 20px 0;
    margin-bottom: 20px;
    .amp-process-asset-label{
        height: 40px;
        line-height: 40px;
    }
}
