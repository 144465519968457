@import "@/stylesheets/global.less";
/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */
/* stylelint-disable selector-max-type */
.amps-business-data {
    .amp-table-score {
        width: 25px;
        height: 20px;
        padding: 0 6px;
        color: #595959;
        font-size: 12px;
        line-height: 20px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
    }

    .amp-table-cell-hidden {
        visibility: hidden;
    }

    // 表格操作按钮
    .amp-blue-btn {
        color: #29c1b9;
    }

    // 额外展开行 间隙
    .ant-table-expanded-row {
        display: none;
    }
}

.ant-tabs-extra-content {
    margin-right: 30px;
}

.amp-table-summary-no-thead {
    .ant-table-row-expand-icon-collapsed {
        visibility: hidden;
    }
}

.amp-business-data-form-buttons {
    margin-left: 6px;
}

.amp-range-picker {
    .to-vw(230)!important;

    min-width: 180px;
    max-width: 230px !important;
    height: 40px !important;
    margin-right: 18px;
}

.amp-business-data-from-page {
    display: flex;
    align-items: normal;
    justify-content: space-between;
}

// 表单相关
.amp-business-data-form {
    display: flex;
    flex-flow: row wrap;

    .ant-form-item-label > label {
        font-weight: 500;
    }

    .amp-input-sz-s {
        .to-vw(120)!important;

        min-width: 100px;
        max-width: 120px !important;
        height: 40px !important;
        margin-right: 12px;
        border-radius: 2px;
    }

    .amp-input-sz-l {
        .to-vw(210)!important;

        min-width: 180px;
        max-width: 210px !important;
        height: 40px !important;
        margin-right: 12px;
    }

    .ant-row {
        flex-flow: row nowrap;

        .ant-form-item-label {
            display: flex;
            align-items: center;
            font-weight: 700px;
        }
    }

    .amp-btn-sz-s {
        width: 100px;
        height: 40px;
    }

    .amp-blue-border {
        margin-right: 10px;
        color: #29c1b9 !important;
        border: 1px solid #29c1b9 !important;
        border-radius: 4px;
    }

    .ant-form-item-label > label::after {
        content: "";
    }
}

.amp-business-data-form-buttons {
    .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-start;
    }
}

// 导出按钮
.amp-business-data-button {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: -1px;
    padding-bottom: 24px;

    .amp-btn-sz-s {
        width: 100px;
        height: 40px;
    }
}

// 行hover background
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) :not(.ant-table-row-selected) > td {
    background: #f3fffd;
}

.amp-calendar-modal {
    min-width: 640px !important;
    overflow-y: scroll;

    .ant-modal-body {
        padding: 16px 0;
    }

    .ant-modal-content {
        padding: 25px 29px 0 50px;
    }

    .ant-modal-close {
        top: 24px !important;
        right: 12px !important;
    }

    .ant-modal-header {
        padding: 16px 0 24px;
        border-bottom: 1px dashed #e5e5e5;
    }

    .ant-modal-title {
        color: #000;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }

    .ant-picker-content > thead > tr > th {
        text-align: center !important;
    }

    .ant-picker-cell-today {
        border: 2px solid #29c1b9 !important;
    }

    .ant-picker-cell-in-view {
        background: #fff;
    }

    .ant-picker-cell-disabled {
        border: none !important;
    }

    .ant-picker-calendar-date {
        margin: 0 !important;
        border: none !important;
    }

    .ant-picker-calendar-date-value {
        text-align: center;
    }

    .amp-calendar-modal-button {
        width: 100%;
        height: 50px;
        background: #fff;
    }

    .amp-btn-sz-s {
        width: 100px;
        height: 40px;
        border-radius: 2px !important;
    }
}

.amp-calendar-modal-button {
    width: 100%;
    height: 50px;
    background: #fff;
}

.amp-calendar-modal-content {
    overflow: scroll;

    ::-webkit-scrollbar {
        display: block;
    }

    .amp-calendar-modal-title {
        margin-bottom: 24px;
        color: #8c8c8c;
        font-weight: 300;
        font-size: 16px;
        line-height: 18px;
    }

    .amp-calendar-modal-select {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    .amp-calendar-modal-date {
        width: 110px;
        height: 40px;
        margin: 0 10px;
        border-radius: 4px;
    }

    .amp-calendar-modal-select-date {
        display: flex;
        margin-right: 20px;
    }

    .amp-calendar-modal-tip {
        color: #000;
    }

    .amp-calendar-modal-tip-primary {
        color: #29c1b9;
    }

    .amp-single-day {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 86px;

        &:hover {
            border: 1px solid #29c1b9;
        }
    }

    .amp-single-gray {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 86px;
        color: #d9d9d9;
        background: #fff !important;
    }

    .amp-single-day-primary {
        background: #f3fffd;
    }

    .amp-single-day-gray {
        background: #fff;
    }

    .amp-events > li {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
    }

    .amp-events-circle {
        display: block;
        width: 8px;
        height: 8px;
        margin-right: 5px;
        border-radius: 50%;
    }

    .amp-events-circle-primary {
        background: #29c1b9;
    }

    .amp-events-circle-gray {
        background: #e5e5e5;
    }

    .amp-score-button {
        color: #c4c4c4;
        border-color: #d9d9d9;

        &:hover {
            color: #4d4d4d;
        }

        &:disabled {
            color: #c4c4c4;
        }
    }
}

.amp-events-circle {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.amp-events-circle-primary {
    background: #29c1b9;
}

.amp-events-circle-red {
    background: #f5222d;
}
// 周选择（周范围：上周六到本周五）
.ant-picker-week-panel-row-selected {
    .ant-picker-cell-week {
        background: #29c1b9 !important;
    }
}

.ant-picker-week-panel-row-selected > td {
    background: none !important;

    .ant-picker-cell-inner:hover {
        color: #000 !important;
    }

    .ant-picker-cell-inner {
        color: #000 !important;
    }
}

.amp-modify-score-modal {
    .ant-row {
        display: list-item;
    }

    .amp-form-input {
        margin: 16px 0;
    }

    .amp-input-number {
        .to-vw(210);
    }

    .amp-form-btn {
        float: right;
        margin-bottom: 2px;
    }
}
