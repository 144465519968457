@import "@/stylesheets/global.less";
/* stylelint-disable declaration-no-important */
.amp-topic-progress {
    width: 100%;
    height: 80px;
    padding-top: 26px;
    overflow-x: scroll;

    .ant-steps-item-finish {
        .ant-steps-item-title {
            color: #595959 !important;
        }
    }

    .ant-steps-item-process {
        .ant-steps-item-title {
            color: #262626 !important;
        }
    }

    .ant-steps-item-wait {
        .ant-steps-item-title {
            color: #8c8c8c !important;
        }
    }
}
