.activities {
  height: 95vh;
  overflow: hidden scroll;
  margin-right: -49px;
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8 !important;
    &:hover {
      background: #b6b6b6 !important;
    }
  }
}

.activities-timeline {
  margin-top: 30px;
}

.activities-date {
  font-size: 16px;
  background: #f8f8f8;
  opacity: 0.8;
  border-radius: 2px;
  width: 176px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}

.announcement-page {
  padding: 52px 190px 100px 190px;
  overflow: auto;
  height: 93vh;
  margin-right: -49px;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  iframe {
    width: 100%;
    height: 72vh;
  }
}

.bund-icon {
  border-radius: 28px;
  width: 62px;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-bund {
  &:extend(.bund-icon);
  color: #6cc640;
  background: #e7ffdb;
  border: 1px solid #add699;
}

.not-bund {
  &:extend(.bund-icon);
  color: #c6c6c6;
  background: #f4f4f4;
}

.wechat-background {
  background: #f8fafa;
  margin-top: 30px;
  padding: 40px 50px;
  margin-left: -50px;
  margin-right: -50px;
  box-sizing: border-box;
  height: 80vh;
}

.wechat-option {
  .to-vw(281);
  .to-vw(154, height);
  .ant-form-item {
    margin: 0 !important;
  }
  background: #ffffff;
  border: 1px solid #dfe8ed;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 50px;
}
.wechat-option-card-top {
  .to-vw(96, height);
  background: #7cd7d2;
  border-radius: 4px 4px 0px 0px;
  padding: 36px 20px;
  box-sizing: border-box;
  color: white;
  font-size: 16px;
  cursor:default;
}
.wechat-option-card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  box-sizing: border-box;
  line-height: 18px;
}
.option-line {
  width: 30px;
  height: 4px;
  background-color: white;
  margin-top: 6px;
}
.wechat-option-disabled {
  &:extend(.wechat-option);
  .wechat-option-card-top {
    background: #dadada;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .wechat-option-card-bottom span {
     color: #757575;
  }
}
