.login-wrapper {
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  padding: 24px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  .to-vw(744);
  .to-vw(1000, height);
  .to-vw(1176, left );
}

.powered-by-ecoplants {
  .to-vw(220);
  position: absolute;
  right: 26px;
  top: 24px;
  z-index: 2000;
}

.login-form {
  margin-top: 60px;
}

.session-bg {
  .to-vw(1176);
  height: 100vh;
  box-sizing: border-box;
  img {
    object-fit: fill;
  }
}

.login-title {
  display: flex;
  .to-vw(220);
  .to-vw(80, height);
  position: relative;
  align-items: center;
  box-sizing: border-box;
  img {
    width: 100%;
  }
}

.session-error {
  .to-vw(25, min-height);
  .to-vw(380);
  color: #ec3b3b;
}

.session-button {
  .to-vw(380) !important;
  .to-vw(44, height) !important;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  &:hover {
    border: none;
    background-color: #7ac3cb;
  }
}
