@import "@/stylesheets/global.less";
.amps-plan-examine-page {
    width: 100%;

    .amp-scene-scroll-container {
        height: calc(100vh - 220px);
        padding: 17px 6px 5px 16px;
        border: 1px solid #e8e8e8;
    }

    .amp-scene-item-detail {
        position: relative;
        display: flex;
        height: calc(100vh - 220px);
    }

    .amp-item-detail-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    ::-webkit-scrollbar {
        display: block;
    }

    ::-webkit-scrollbar-button {
        height: 0;
    }

    .amp-list-item {
        display: flex;
        margin-bottom: 16px;
    }

    .amp-item {
        flex: 0 0 49%;
        min-width: 1px;
        margin-right: 6px;
        border: 1px solid #e8e8e8;

        &:hover {
            border-color: #4ccfc4;
        }

        .amp-item-content {
            box-sizing: border-box;
            padding: 8px;
        }

        .amp-selected-content {
            border: 1px solid #4ccfc4;
            border-top: none;
        }

        .amp-line-clamp-1 {
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .amp-item-title {
        height: 20px;
        color: #000;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }

    .amp-item-tips {
        height: 20px;
        color: #262626;
        font-size: 12px;
        line-height: 20px;
    }

    .amp-item-img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .to-vh(108);

        min-height: 108px;
        max-height: 100%;
        background-color: #000;

        &::after {
            display: block;
            padding-bottom: 61.8%;
            content: "";
        }

        .ant-image {
            width: 100%;
            height: 100%;
        }

        .amp-ant-image {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .amp-item-detail {
        position: relative;
        flex: 1;
        min-width: 0;
        height: 100%;
        padding: 0 16px;
    }

    .amp-item-detail-left {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 220px);
        padding-right: 8px;
        padding-bottom: 100px;
        overflow-y: scroll;

        .amp-line-clamp-1 {
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .amp-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;
        }

        .amp-tip {
            min-width: max-content;
            padding: 2px 8px;
            font-size: 12px;
            word-wrap: normal;
            border: 1px solid #bfbfbf;
            border-radius: 4px;
        }

        .amp-label {
            min-width: 32px;
            margin-right: 8px;
        }

        .amp-title-flex {
            align-items: flex-start;
        }

        .amp-content-flex {
            display: flex;
        }

        .amp-address-icon {
            margin: -3px 2px 0 0;
        }
    }

    .amp-swiper {

        .to-vh(348);

        width: 100%;
        min-height: 348px;
        margin-bottom: 16px;
    }

    .amp-item-detail-right {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 220px);
        padding-top: 40px;
        padding-right: 8px;
        padding-bottom: 100px;
        overflow-y: scroll;

        .amp-title {
            height: 22px;
            font-size: 14px;
            line-height: 22px;
        }

        .amp-relation-info-title {
            display: block;
            margin: 16px 0 8px;
            font-size: 14px;
        }

        .amp-relation-info-item {
            padding: 8px 16px;
            font-size: 12px;
            background: #fafafa;
            cursor: pointer;
        }

        .amp-comments-info-title {
            display: flex;
            justify-content: space-between;
        }

        .amp-comments-info-btn {
            padding-left: 16px;
            border-left: 1px solid #e8e8e8;
        }

        .amp-btn {
            color: #595959;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
        }
    }

    .amp-history-info-top {
        display: flex;
        justify-content: flex-start;
    }

    .amp-relation-info-top {
        display: flex;
        justify-content: flex-start;
    }

    .amp-item-detail-bottom {
        position: absolute;
        bottom: -16px;
        left: 0;
        z-index: 1;
        width: 100%;
        padding: 15px 0 30px 24px;
        background: #fff;
        box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.03);

        .amp-button {
            height: 40px;
            padding: 8px 44px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
        }
    }

    .amp-gray {
        color: #595959;
    }

    .amp-fs-24 {
        font-size: 24px;
    }

    .amp-fs-16 {
        font-size: 16px;
    }

    .amp-ml-8 {
        margin-left: 8px;
    }

    .amp-fw-500 {
        font-weight: 500;
    }

    .amp-mg-16 {
        margin: 0 16px;
    }

    .amp-mb-8 {
        margin-bottom: 8px;
    }

    .amp-mb-24 {
        margin-bottom: 24px;
    }

    .amp-action-input {
        max-width: 300px;
        height: 40px;
    }

    .ant-form-item-label {
        font-weight: 500;
    }
}
