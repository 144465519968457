@import "@/stylesheets/global.less";
.amp-settings-target {
    .amp-target-tips {
        padding: 0 15px;
        font-size: 20px;
        line-height: 30px;
    }

    .amp-target-table-thead {
        height: 40px;
        line-height: 40px;
        background: #f7f7f9;
        border-radius: 4px;
    }

    .amp-target-table-input {
        height: 36px;
        text-align: center;
    }

    .amp-target-table-text {
        height: 36px;
        overflow: hidden;
        line-height: 34px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;

        &:hover {
            border-color: #4ccfc4;
        }
    }

    .amp-target-table-disabled {
        background: #f5f5f5;
        cursor: not-allowed;
    }

    .amp-target-table .ant-table-body {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .amp-target-table-place {
        color: #e9e9e9;
        font-size: 12px;
        white-space: nowrap;
    }

    .amp-target-table .ant-table-cell {
        padding: 5px;
        border: none;
    }

    .ant-table-thead .ant-table-cell {
        background: none;
    }
}
