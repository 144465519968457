@import "@/stylesheets/global.less";
.amp-task-package-table {
    display: flex;
    flex-flow: row wrap;
    >div {
        width: 100%;
    }
}

.amp-task-package-title {
    margin-top: 10px;
    margin-bottom: 16px;
}

.amp-color-red {
    color: red;
}

.amp-assets-info-item {
    display: flex;
}

.amp-w-50 {
    flex: 0 1 50%;
}

.amp-w-100 {
    flex: 0 1 100%;
}

.people-display{
    margin-top: 0 !important;
}

.amp-assets-info-item-photo {
    display: flex;
    flex: 0 1 100%;
    flex-wrap: wrap;
    margin-top: -2px;
    .amp-img {
        width: 120px;
        height: 120px;
        margin-right: 10px;
    }
}

// 任务信息
.amps-plan-task-img {
    width: 80px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 0.5px solid #bfc5d1;
    background: #ffffff;
    .task-img-body {
      width: 68px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000000;
      margin-top: 6px;
      .assets-img {
        width: 35.77px;
        height: 51.35px;
      }
    }
    .task-text {
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      margin-top: 4px;
    }
    .background-invalid {
      background: rgba(0, 0, 0, 0.6);
    }
    .text-invalid {
      opacity: 0.6;
    }
  }
  .amp-border-no-see {
    border: 1px solid #9b9b9b;
}

.amp-border-pass {
    border: 1px solid #29c1b9;
}

.amp-border-reject {
    border: 0.5px solid #fb3152;
}

.amp-border-invalid {
    border: 1px solid #bfc5d1;
}
