@import "@/stylesheets/global.less";
.amps-trip-content {
    .amp-content-table {
        .ant-table-tbody {
            display: none;
        }
    }

    .amp-list-table {
        /* stylelint-disable-next-line selector-max-type */
        .ant-table-thead,
        .ant-table-tbody,
        .ant-table tfoot {
            & > tr {
                & > td,
                & > th {
                    &:first-child {
                        padding-left: 32px;
                    }

                    &:last-child {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }
        }
        .ant-table-thead {
            .ant-table-cell {
                color: #19233C;
                font-size: 14px;
                font-weight: 300;
            }
        }
        .bg-white {
            background-color: #fff;
        }
        border: 1px solid #f2f2f2;
    }
}
