.projects-head {
    display: flex;
    justify-content: space-between;
}

.table-head {
    font-weight: 700;
}

.ant-pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.produce-score {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 1px solid #888888;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 8px;
}

.produce-score-color {
    background-color: #29C1B9;
    ;
}

.produceTaskPondModel {
    overflow: hidden;

    .ant-modal-content {
        .ant-modal-body {
            padding: 16px 0 15px 0 !important;
            height: 820px;
            overflow: hidden !important;
        }
    }
}

.pond-title {
    font-size: 18px;
    font-weight: 400;
    height: 51px;
    line-height: 36px;
    padding-left: 32px;
    border: 0px solid #E4E7EF;
    border-bottom-width: 1px;
    font-family: Noto Sans SC;

    span {
        cursor: pointer;
    }
}

.produce-take-count {
    &:hover {
        color: #29C1B9;
        cursor: pointer;
    }
}

.pond-reset {
    color: #191919;
    font-weight: bolder;
    font-size: 15px;
    cursor: pointer;
}

.pond-description {
    padding: 0 32px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.pond-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;
    padding-left: 32px;
    padding-top: 20px
}

.IconHeight {
    width: 43px;
    height: 19px;
    display: inline-block;

    span {
        width: 100%;
        height: 100%;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.pond-middle {
    display: flex;
    flex-direction: column;
    //   height: 626px;
    height: 615px;

    .pond-middle-header {
        padding: 0 32px;

        .pond-filtrate-by-type {
            margin-top: 16px;

            .pond-filtrate {
                border: 1px solid #E4E7EF;
                font-size: 14px;
                font-weight: 300;
                padding: 0px 12px;
                margin-bottom: 16px;
                height: 26px;
                line-height: 26px;
                border-radius: 20px;
                display: inline-block;
                font-family: Noto Sans SC;
            }
        }
    }

    .pond-content {
        flex: 1;
        padding: 0 24px;
        padding-right: 18px;
        overflow: scroll;

        .loadingSpin {
            height: 40px;
            width: 100%;
            text-align: center;
            bottom: 10px;
            position: relative;
            font-size: 14px;
        }

        .pond-empty {
            width: 100%;
            top: 50%;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            margin-top: -54px;
            position: relative;
            text-align: center;

            p {
                color: #818181;
                font-size: 14px;
                font-weight: 300;
            }
        }

        .single-task {
            box-sizing: border-box;
            width: 15.625vw;
            display: inline-block;
            margin-bottom: 16px;
            height: 172px;
            padding: 14px;
            border-radius: 10px;
            border: 1px solid #E8E8E8;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);

            &-active {
                border: 1px solid #29C1B9 !important;
            }

            &-operate {
                cursor: pointer;
            }

            .header {
                height: 30px;
                margin-bottom: 0;
                position: relative;

                .header-icon {
                    width: 20px;
                    display: inline-block;
                }

                .checked-icon {
                    float: right;
                    display: none;
                    cursor: pointer;
                    height: 30px;
                    width: 30px;

                    span {
                        height: 100%;
                        width: 100%;
                        display: inline-block;

                        svg {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                .split-icon {
                    cursor: pointer;
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    margin-left: 6px;

                    span {
                        height: 100%;
                        width: 100%;
                        display: inline-block;

                        svg {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }

            .details {
                height: 20px;
                line-height: 20px;
                width: 100%;
                overflow: hidden;
                display: flex;

                span {
                    display: inline-block;
                    vertical-align: top;
                }

                .title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1;
                }
            }

            .potos {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                overflow: hidden;
                height: 88px;

                .single-photo {
                    display: inline-block;
                    margin-right: 10px;

                    .model-image {
                        -webkit-filter: opacity(20%);
                        filter: opacity(20%);
                    }
                }
            }

            &:hover {
                border-color: #29C1B9;
            }
        }

        &::-webkit-scrollbar {
            display: block;
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            border: 4px solid transparent;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-button {
            height: 0 !important;
            width: 0 !important;
        }

        &::-webkit-scrollbar-thumb {
            background: #c8c8c8 !important;
            border-radius: 40px;
            width: 50%;
            border: 3px solid transparent;

            &:horizontal:hover {
                background: #b6b6b6 !important;
            }
        }
    }
}

.bg-blue {
    background-color: #29C1B9;
    color: #fff;
}

.bg-white {
    background-color: #fff;
    color: #000;
}

.bg-gray {
    color: #E0E0E0;
}

.made-scroll {
    .ant-input-number-input-wrap {
        height: 100% !important;

        input {
            height: 100% !important;
        }
    }
}

.font-color {
    color: rgba(14, 17, 20, 1);
    font-weight: 300;
    font-size: 14px;
}

.amp-origin-border {
    border-bottom: 1px solid #E0E0E0;
}
