@import "@/stylesheets/global.less";
.amp-destroy-chart {
    padding-top: 16px;

    .amp-destroy-chart-title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 18px;
    }

    .amp-destroy-chart-title-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-color: #29c1b9;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
    }

    .amp-flex-row-start-center {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .amp-flex-row-between-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .amp-destroy-card {
        min-width: 280px;
        margin-right: 30px;
        margin-bottom: 16px;
        padding: 16px 24px;
        background: #fff;
        border: 1px solid rgba(229, 229, 229, 0.25);
        border-radius: 6px;
        box-shadow: 0 8px 20px rgba(229, 229, 229, 0.5);
    }

    .amp-fs-16 {
        font-size: 16px;
    }

    .amp-mb-16 {
        margin-bottom: 16px;
    }

    .amp-mr-10 {
        margin-right: 10px;
    }

    .amp-mr-30 {
        margin-right: 30px;
    }

    .amp-pt-16 {
        padding-top: 16px;
    }

    .amp-destroy-main-font {
        min-height: 30px;
        color: #29c1b9;
        font-size: 28px;
    }

    .amp-destroy-chart-from {
        display: flex;
        align-items: center;
    }

    .amp-destroy-chart-from-title {
        margin-right: 10px;
        margin-bottom: 24px;
        font-size: 14px;
    }

    .amp-destroy-tips2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 32px;
        padding-left: 8px;
        color: #000;
        font-weight: 400;
        font-size: 14px;
        background-color: #f4f5f7;
    }

    .amp-destroy-cycle-select {
        min-width: 120px;
        height: auto;
    }
}
