@import "@/stylesheets/global.less";
.amps-topic-info {
    float: right;
    width: 30%;
    height: 100%;
    padding: 0 11px 16px 16px;
    overflow-y: scroll;
    border-left: 1px solid #e8e8e8;

    &::-webkit-scrollbar {
        display: block;
    }
}
