@import "@/stylesheets/global.less";
.amps-details-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .amp-details-content-main {
        height: calc(100% - 70px);
    }
}
