@import "@/stylesheets/global.less";
.amps-select-plan-user {
  .users-form {
    .ant-form-item-label {
      padding-bottom: 14px !important;
      line-height: 20px;
    }
  }

  .user-avatar {
    margin-left: 30px;
    .ant-avatar {
      font-size: 14px !important;
    }
  }
  .ant-select-selector {
    height: 36px !important;
  }
}
