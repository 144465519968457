@import "@/stylesheets/global.less";
.amps-voidForm {
    .desc-field {
        margin-top: 34px;
        .label {
            margin-right: 30px;
            display: inline-block;
            color: #000000;
            font-weight: bold
        }

        .value {
            display: block;
            margin-top: 23px;
            color: #000000;

        }

    }
    .desc-field-flex{
        margin-top: 34px;
        display: flex;
        .label {
            margin-right: 30px;
            display: inline-block;
            color: #000000;
            font-weight: bold
        }
        .images{
        }
    }
}
