@import "@/stylesheets/global.less";
.amps-filter {
    .filter-content {
        margin-top: 27px;
        margin-bottom: 30px;
        height: 180px;
        overflow: scroll;
        .filter-content-loading {
            width: 100%;
            height: 100%;
            text-align: center;
            margin-top: 80px;
        }
    }
}
