@import "@/stylesheets/global.less";
.amp-fixed-input{
    height: 40px;
    border-radius: 4px;
    width: 100% !important;
    min-width: 150px;
    .ant-input-number-input{
        height: 40px;

    }
}
