.mg-l-20 {
  margin-left: 20px;
}
.mg-t-3 {
  margin-top: 3px;
}

.mg-t-6 {
  margin-top: 6px;
}
.mg-t-8 {
  margin-top: 8px;
}

.mg-l-7 {
  margin-left: 7px;
}
.mg-l-8 {
  margin-left: 8px;
}
.amp-mg-l-8{
    margin-left: 8px;
}
.mg-l-9 {
  margin-left: 9px;
}
.mg-l-16 {
  margin-left: 16px;
}
.mg-l-30 {
  margin-left: 30px;
}

.mg-l-40 {
  margin-left: 40px;
}

.mg-l-50 {
  margin-left: 50px;
}

.mg-t-5 {
  margin-top: 5px;
}
.mg-t-4 {
  margin-top: 4px;
}

.mg-t-12 {
  margin-top: 12px;
}

.mg-t-14 {
    margin-top: 14px;
  }

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-l-12 {
  margin-left: 12px;
}
.mg-b-14 {
  margin-bottom: 14px;
}

.mg-b-16 {
  margin-bottom: 16px;
}

.mg-t-17 {
  margin-top: 17px;
}
.mg-r-3 {
    margin-right: 3px;
}

.mg-r-30 {
    margin-right: 30px;
}

.mg-r-17 {
  margin-right: 17px;
}

.mg-r-20 {
  margin-right: 20px;
}

.mg-r-26 {
    margin-right: 26px;
  }

.mg-r-10 {
  margin-right: 10px;
}

.mg-r-8 {
  margin-right: 8px;
}

.amp-mg-r-8{
    margin-right: 8px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-l-10-f {
  margin-left: -10px;
}

.mg-r-18 {
  margin-right: 18px;
}

.mg-r-32 {
  margin-right: 32px;
}

.mg-r-34 {
    margin-right: 34px;
  }

.mg-b-4 {
  margin-bottom: 4px;
}

.mg-b-42 {
  margin-bottom: 42px;
}

.mg-b-40 {
  margin-bottom: 40px;
}

.mg-b-50 {
  margin-bottom: 50px;
}

.mg-t-42 {
  margin-top: 42px;
}
.mg-b-68 {
  margin-bottom: 68px;
}

.mg-b-6 {
  margin-bottom: 6px;
}

.mg-tb-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mg-tb-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mg-tb-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mg-t-16 {
  margin-top: 16px;
}
.mg-t-30 {
  margin-top: 30px;
}
.mg-t-33 {
  margin-top: 33px;
}
.mg-tb-30 {
  margin: 30px 0px;
}

.mg-lr-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mg-r-140 {
  margin-right: 140px;
}

.mg-b-8 {
  margin-bottom: 8px;
}

.mg-r-12 {
  margin-right: 12px;
}

.mg-r-5 {
  margin-right: 5px;
}
.mg-r-3 {
  margin-right: 3px;
}

.mg-r-4 {
  margin-right: 4px;
}

.mg-r-43 {
  margin-right: 43px;
}

.mg-l-4 {
  margin-left: 4px;
}
.mg-l-14 {
  margin-left: 14px;
}

.mg-r-14 {
  margin-right: 14px;
}
.mg-r-16 {
  margin-right: 16px;
}
.mg-r-6 {
  margin-right: 6px;
}
.mg-r-9 {
  margin-right: 9px;
}
.mg-b-13 {
  margin-bottom: 13px;
}

.mg-b-12 {
  margin-bottom: 12px;
}

.mg-l-16 {
  margin-left: 16px;
}
.mg-l-60 {
  margin-left: 50px;
}

.pd-b-16 {
  padding-bottom: 16px;
}

.pd-b-28 {
  padding-bottom: 28px;
}

.mg-tb-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mg-b-35 {
  margin-bottom: 35px;
}

.mg-b-30 {
  margin-bottom: 30px;
}
.mg-b-29 {
  margin-bottom: 29px;
}
.mg-b-28 {
  margin-bottom: 28px;
}
.mg-b-20 {
  margin-bottom: 20px;
}

.mg-t-20 {
  margin-top: 20px;
}
.mg-t-22 {
  margin-top: 22px;
}
.mg-lr-26 {
  margin: 0px 26px;
}
.mg-t-60 {
  margin-top: 60px;
}
.mg-b-43 {
  margin-bottom: 43px;
}
.mg-b-22 {
  margin-bottom: 22px;
}
.mg-b-24 {
  margin-bottom: 24px;
}
.mg-b-46 {
  margin-bottom: 46px;
}
.mg-r-22 {
  margin-right: 22px;
}
.mg-r-50 {
  margin-right: 50px;
}
.mg-b-18 {
  margin-bottom: 18px;
}
.mg-t-30h {
  margin-top: 30vh;
}

.mg-r-60 {
  margin-right: 60px;
}

.mg-t-68 {
  margin-top: 68px;
}
