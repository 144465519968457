.history-item {
  display: flex;
  position: relative;
  line-height: 18px;
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 14px;
  height: fit-content;
}

.history-item-title {
  .to-vw(78);
}

.history-content {
  margin-top: 40px;
  margin-left: -26px;
}

.two-col-history {
  display: flex;
  .to-vw(840);
  flex-flow: row wrap;
  position: relative;

  >div {
    width: 50%;
  }
  .history-item-content {
    .to-vw(340);
  }
}

.one-col-history {
  display: flex;
  .to-vw(840);
  flex-flow: row wrap;
  position: relative;
  >div {
    width: 100%;
  }
  .history-item-content {
    .to-vw(740);
  }
}

.three-col-history {
  display: flex;
  .to-vw(840);
  flex-flow: row wrap;
  position: relative;

  >div {
    width: 33%;
  }

    .history-item-content {
    .to-vw(198);
  }
}

.people-display {
  display: flex;
  margin-top: -12px;
  flex-flow: row wrap;
  .ant-avatar {
    margin-bottom: 8px;
  }
}

.history-headline {
  margin-top: 30px;
  margin-bottom: 16px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  right: auto;
  left: 350px;
}

.history-images {
  margin-left: -7px;
  display: flex;
  flex-flow: row wrap;
  .ant-image {
    margin: 7px;
    .ant-image-img {
      object-fit: contain;
      background-color: black;
    }
  }
}

.history-timeline-title {
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 40px;
}

.history-person {
  margin-bottom: 30px;

  .in-block {
    display: inline-block;
  }

  .history-person-left {
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }

  .history-person-right {
    width: 28px;
    height: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 28px;
    background: #C9CCCF;
    color: #2B364F;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
    text-align: center;
  }
}

.collapse-header {
  transition: all 0.3s, visibility 0s;
  transition-property: all, visibility;
  transition-duration: 0.3s, 0s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
}

.collapse-arrow svg {
  transition: transform 0.24s;
  transition-property: transform;
  transition-duration: 0.24s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.collapse-content {
  transition: all 0.3s;
  transition-timing-function: ease;
  margin-top: 4px;
  .ant-timeline-item {
    padding-bottom: 0 !important;
    .ant-timeline-item-tail {
      height: calc(100% - 10px);
    }
  }
}

.history-item-image {
  .to-vw(740);
  display: flex;
  flex-flow: row wrap;
  height: fit-content;
  margin-top: 4px
}



.history-item-col-2 {
  width: 50%;
  .history-item-content {
    .to-vw(340);
  }
}

.history-item-col-1 {
  width: 100%;
  .history-item-content {
    .to-vw(740);
  }
}
.amp-history-item-col-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    .history-item-content {
      .to-vw(340);
    }
  }

.history-record-area {
  .to-vw(840);
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-top: 8px;
}

.history-step-basic {
  width: 24px;
  height: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px;
}

.current-history-step {
  &:extend(.history-step-basic);
  background: #29C1B9;
  color: #ffffff;
}

.past-history-step {
    &:extend(.history-step-basic);

  background:#f6f6f6;
  color: #000000;
}

.future-history-step {
    &:extend(.history-step-basic);

  background:#f6f6f6;
  color: #d7d7d7;
}
