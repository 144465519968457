@import "@/stylesheets/global.less";
.amps-image-swiper {
    height: 100%;
    .amps-image {
        width: 100%;
        height: 100%;
        .ant-image {
            width: 100%;
            height: 100%;
        }
        .amps-image-mask {
            display: none;
        }
    }

    .amps-image:hover {
        .amps-image-mask {
            display: block;
            background-color: transparent;
            top: 0;
        }
    }
}
