h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
}

h2 {
    font-weight: 700 ;
    font-size: 24px ;
    line-height: 36px;
}

h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
}

// body {
//     font-size: 14px;
//     line-height: 18px;
// }

.fz-12 {
    font-size: 12px;
}
.fz-14 {
    font-size: 14px;
}
.fz-16 {
    font-size: 16px;
}

.fz-18 {
    font-size: 18px;
}

.fz-24 {
    font-size: 24px;
}

.fz-32 {
    font-size: 32px;
}

.fz-40 {
    font-size: 40px;
}

strong {
    font-weight: 700;
}

.bold {
    font-weight: 700;
}
.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.ellipsis {
    text-overflow: ellipsis;
}
