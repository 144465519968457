.board-border {

  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 16px;
  height: 100%;
//   padding-top: 20px
//   padding-left: 20px;
//   width: 33%;
  padding: 20px;
  overflow: scroll;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
.between {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.around {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.board-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    color: #29c1b9;
  }
}

.board-view-all {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

}
.board-hover:hover {
  color: #008e87;
  cursor: pointer;
}
.board-number {
  color: #1a9da6;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
.pd-r-30 {
  padding-right: 30px;
}
.pd-b-24 {
  padding-bottom: 24px;
}
.pd-lr-30 {
  padding: 0px 30px;
}
.pd-tb-18 {
  padding: 18px 0px;
}

.tr {
  text-align: right;
}
.in-block {
  display: inline-block;
}
.float-r {
  float: right;
}
.w-176 {
  width: 176px;
}
.amp-flex-row-start{
    display: flex;
    justify-content: flex-start;
}
.width-274 {
  width: 274px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.width-254 {
  display: inline-block;
  max-width: 350px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.matter-onlyHeader {
    .ant-table-tbody {
        display: none;
    }
    .ant-table-thead {
        th:first-child {
            padding-left: 50px;
        }
    }
}
.matter {
  .ant-table-body {
    cursor: pointer;
  }
  .ant-table-thead {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
  .ant-table-tbody > tr {
    height: 80px;
    overflow: hidden;
  }
  .ant-table-tbody > tr > td {
    padding-top: 6px;
    padding-bottom: 0;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 50px;
  }
  .ant-table-row td:first-child {
    padding-left: 50px;
  }
}
.ant-empty {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ta-r {
  text-align: right;
}
.cursor {
  cursor: pointer;
}
