@import "@/stylesheets/global.less";
.amps-annotation {
    position: relative;

    .amp-annotation-header-title-icon {
        position: absolute;
    }

    .amp-annotation-header-operation {
        position: absolute;
        right: 0;
        padding-left: 16px;
        border-left: 1px solid #e8e8e8;
    }

    .amp-annotation-header-title-content {
        align-items: center;
        margin-left: 32px;
        color: #000;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .amp-add-annotation {
        padding: 2px 9px;
        color: #595959;
        font-weight: 400;
        font-size: 12px;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        cursor: pointer;
    }

    .amp-annotation-content {
        text-align: center;
    }

    .amp-b-8 {
        margin-bottom: 8px;
    }
}
