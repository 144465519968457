@import "@/stylesheets/global.less";
.amps-mack-icon {
    height: 25px;
    flex: 1;
    min-width: 25px;
    max-width: 60px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 2px;
    &:hover {
        background-color: #ffffff15;
    }
}
