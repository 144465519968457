.display-flex {
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.align-center {
  align-items: center;
  display: flex;
}
.space-between {
  justify-content: space-between;
  display: flex;
}
.space-evenly {
  justify-content: space-evenly;
  display: flex;
}

.space-around {
  justify-content: space-around;
  display: flex;
}

.justify-center {
  justify-content: center;
  display: flex;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}

.absolute {
  position: absolute;
}


.position-absolute {
  position: absolute;
}