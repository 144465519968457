@import "@/stylesheets/global.less";
.amps-annotation-item {
    padding: 8px 16px;
    text-align: left;
    border: 1px solid #d9d9d9;

    .amp-annotation-item-header {
        position: relative;
        height: 30px;
        margin-bottom: 6px;
        line-height: 30px;

        .amp-annotation-item-header-name {
            display: inline-block;
            margin-right: 8px;
            color: #000;
            font-weight: 400;
            font-size: 12px;
        }

        .amp-annotation-item-header-time {
            display: inline-block;
            color: #595959;
            font-weight: 400;
            font-size: 12px;
        }

        .amp-annotation-item-header-operation {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .amp-annotation-item-title {
        margin-right: 50px;
        padding: 2px 8px;
        color: #262626;
        font-size: 12px;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
    }

    .amp-annotation-item-operate {
        position: absolute;
        top: 5px;
        right: 10px;
        display: inline-block;
        box-sizing: border-box;
        margin-left: 16px;
        line-height: 30px;
        text-align: right;
        cursor: pointer;

        .amp-annotation-item-operate-list {
            position: absolute;
            top: 24px;
            right: -26px;
            display: none;
            padding: 0 11px;
            text-align: left;
            background: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            box-shadow: 0 1px 3px 2px rgba(136, 136, 136, 0.1);

            &:hover {
                background-color: #29c1b9;
            }
        }

        .amp-annotation-delete {
            width: max-content;
            padding: 0 11px;
            color: #000;
            font-weight: 400;
            font-size: 12px;
            font-style: normal;
            word-wrap: normal;

            &:hover {
                color: #fff;
            }
        }

        &:hover {
            .amp-annotation-item-operate-list {
                display: block;
            }
        }
    }

    .amp-annotation-item-comment {
        margin-bottom: 8px;
        color: #595959;
        font-size: 12px;
    }

    .amp-annotation-item-image {
        display: flex;
        width: 100%;
        height: 100px;

        .amp-annotation-item-image-render {
            flex: 0.33;
            height: 100%;
            margin-right: 8px;
            text-align: center;
            background-color: #000;
        }
    }

    .ant-image {
        height: 100%;
    }

    .ant-image-img {
        height: 100%;
        object-fit: cover;
    }
}
