.settings-role-head {
  display: flex;
  justify-content: space-between;
}
.role-permissions{
  .ant-tree-list-holder-inner{
    background-color: #F5F5F5;
    padding: 21px 40px;
    height: 372px;
    overflow-y: scroll;
  }
}

.ant-tree-node-selected {
  background-color: transparent !important;
}

.swiper {
    width: 100%;
    height: 100%;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-slide .ant-image-error img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: none;
}

// .ant-image-error img {
//     object-fit: none;
// }

.swiper-button-next, .swiper-button-prev {
    width: 64px !important;
    height: 64px !important;
    background-color: #F5F5F5;
    border-radius: 50%;
    color: #BFBFBF !important;
    opacity: 0.35;
    margin-top: -32px !important;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
    opacity: 0.5;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}
