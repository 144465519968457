@import "@/stylesheets/global.less";
.amps-topic-cycle-image {
    position: relative;
    height: 100%;

    .ant-empty {
        height: 100%;
        padding-top: 100px;
    }
}
