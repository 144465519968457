.spinLocation {
    position: relative;
    top: 50%;
    left: 50%;
}

.asset-water-fall {
    background-color: #000;
    width: 94.584vw;
    overflow-y: scroll;
    height: 82.593vh;
    padding-top: 8px;
    .waterfall-preview-box{
        height: 15.208vw;
        width: 100%;
        margin-bottom: 0.416vw;
        .render-item{
            height: 100%;
            display: inline-block;
            margin-right: 0.416vw;
            background-color: #0F0F0F;
            .ant-image {
                height: 100%;
                width: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
            span {
                color: #484848;
                z-index: 20;
                position: relative;
                float: left;
                bottom: 28px;
                font-size: 12px;
                margin: 0 8px;
                display: none;
                width: 120px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            span:last-child{
                width: 110px;
                text-align: right;
                float: right;
            }
            span:hover {
                color: #29C1B9;
                cursor: pointer;
            }
        }
        .render-item:hover{
            border: 1px solid #3C3C3C;
            span {
                display: inline-block;
            }
        }
        :last-child {
            margin-right: 0;
        }
    }
}

.asset-water-fall {
    .ant-empty-description {
        color: #D7D7D7;
        font-family: Noto Sans SC;
    }
}

.editAsset {
    overflow-y: scroll;
    .ant-modal-body{
        height: 548px;
    }
}

.assets-update-box {
    margin: 12px 28px 24px 28px;
}

.assets-update-title {
    font-size: 32px;
    font-weight: 700;
}

.asset-update-category{
    width: 260px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    margin-left: 16px;
    box-sizing: border-box;
    .ant-select-selection-search {
        margin-left: 10px!important;
    }
    .ant-select-selection-item {
        margin-left: 10px!important;
    }
}

.asset-update-footer {
    margin-top: 68px;
    text-align: center;
    button {
        width: 120px;
        height: 40px;
        border: none;
        box-sizing: border-box;
        padding: 0;
        font-size: 14px;
        font-family: Noto Sans SC;
        border: 1px solid #D9D9D9;
        border-radius: 4px;
    }
    :first-child {
        background-color: #29C1B9;
        color: #FFF;
        &:hover{
            background-color: #4ccfc4;
            border-color: #4ccfc4;
        }
    }
    :last-child {
        margin-left: 20px;
        background-color: #fff;
        &:hover{
            color: #4ccfc4;
            border-color: #4ccfc4;
        }
    }
}

.asset-update-id-name {
    width: 259px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    border-radius: 5px;
    box-sizing: border-box;
    padding-left: 20px;
    box-sizing: border-box;
    background-color: #F6F6F6;
    border : 1px solid #D9D9D9;
    color: #B8B8B8;
    font-size: 14px;
}

.asset-update-span{
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    font-family: Noto Sans SC;
}

.asset-update-img {
    width: 68px;
    height: 68px;
    display: inline-block;
    margin-left: 30px;
    img {
        width: 100%;
        height: 100%;
        vertical-align: text-top;
    }
}
.amps_asset_water_fall_v2{
    align-content: flex-start;
    background-color: #000;
    padding-top: 8px;
    height: 82.593vh;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &_init_loading{
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        color: #484848;
        text-align: center;
    }
    &_footer{
        position: relative;
        height: 20px;
        width: 100%;
        flex: 0 0 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 10px;
        &_text{
            color: #fff;
            font-size: 14px;
            text-align: center;
        }
    }
}
