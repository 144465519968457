.header-bar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfc;
  color: black;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 50px 0 50px;
  border-bottom: 2px solid #f2f2f2;

}

.header-tabs {
//   width: 100%;
  display: flex;
  justify-content: space-around;
  min-width: 40vw;
}

.header-tab {
//   .to-vw(150);
  .to-vw(66,height);
  .to-vw(66,line-height);
  display: flex;
  justify-content: center;
  color: black;
  box-sizing: border-box;
}

.header-tab-active {
  &:extend(.header-tab);
  color: #29c1b9;
}

.header-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  cursor: pointer;
  .ant-avatar {
    cursor: pointer;
  }
  .display-flex{
    min-width: 138px;
  }
}

.header-logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  max-width: 355px;
  min-width: 275px;
  top: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    margin-right: 12px;
    display: block;
    object-fit: contain;
  }
}


.notice-content {
  max-height: 132px;
  max-width: 294px;
  overflow-y: auto;
  .ant-popover-inner-content {
      padding: 18px 30px;
  }
}
.relative {
  position: relative
}
.header-notice-number {
  background: #FF4D4F;
  border-radius: 20px;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  padding: 0px 6px;
  position: absolute;
  top: -10px;
  left:6px
}
.ant-badge-count {
  background: #29C1B9
}
