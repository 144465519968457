.error-color {
    color: #ec3b3b;
}
.col-66{
    color: #66B2BB;
}
.col-9b {
    color: #9b9b9b
}

.bg-trans {
    background: transparent !important;
}
.bg-red{
    background: red;
}
.col-6D {
    color: #6D6D6D;
}

.col-00 {
    color: #000;
}
.col-w {
    color: white;
}

.primary-color {
    color: #29C1B9;
}
.col-48 {
    color: #484848;
}