.dashed-square-display {
  min-width:148px;
  min-height: 90px;
//   .to-vw(200);
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  &:last-of-type {
    margin-right: 0;
  }
}

.square-display-data {
  display: flex;
  justify-content: center;
  .to-vw(8, padding-top);
  .to-vw(13, padding-bottom);
  line-height: 20px;
  font-size: 32px;
}

.single-rank-item {
  .to-vw(545);
  .to-vw(56, height);
  background: #f5f5f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  color: black;
  margin-top: 8px;
  margin-bottom: 8px;
  &:first-child{
    margin-top: 0px;
  }
}
.rank-normal {
  width: 36px;
  height: 36px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-top {
  &:extend(.rank-normal);
  background: #e9daff;
  border-radius: 4px;
  color: #8b3eff;
}

.rank-table {
  .to-vw(600);
  padding: 20px 30px;
  box-sizing: border-box;
  background: #fbfbfc;
  overflow-y: scroll
}

.rank-table-scroll {
  .to-vw(550);
  overflow: auto;
}

.bar-box{
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  .bar{
    flex: 1;
    padding-top: 20px;
    position: relative;
    height: 100%;
    background: #fbfbfc;
  }
}

.rank-table-labels {
  .to-vw(545);
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.rank-item-base {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rank-sort {
  &:extend(.rank-item-base);
  width: 2.87vw;
}
.rank-name {
  &:extend(.rank-item-base);
  width: 6.875vw;
}
.rank-score {
  &:extend(.rank-item-base);
  width: 7.6vw;
}
.rank-task {
  &:extend(.rank-item-base);
  width: 10.8vw;
}

.summary-table {
//   .to-vw(1562);
//   .to-vw(162, height);
  background: #fbfbfc;
  border-radius: 4px;
  padding: 14px 32px 14px 30px;
  box-sizing: border-box;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
}

.show-detail {
  color: #00ada4;
  background: #d4f3f1;
  width: 64px;
  height: 24px;
  border-radius: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #53cdc6;
    color: #ffffff;
  }
}
