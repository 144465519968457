@import "@/stylesheets/global.less";
@import "../../../stylesheets/theme";
.amps-button-default {
    min-width: 120px;
    background-color: transparent;
    border: 1px solid @border-color;
    height: 40px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);

    &:hover {
        color: @primary-color;
    }
}


.amps-button-primary {
    min-width: 120px;
    background-color: @primary-color !important;
    color: @secondary-text-color !important;

    &:hover {
        background-color: fadein(@primary-color, 0.04);
    }
}

.amps-button-link {
    color: @primary-color !important;
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;

    &:hover {
        background-color: fade(@primary-color, 4) !important;
    }
}

.amps-button-text {
    color: @primary-text-color !important;
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    font-weight: 700 !important;

    &:hover {
        color: @primary-color !important;
    }
}
.amps-button {
    box-sizing: border-box;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
    line-height: 20px !important;
    padding: 10px 16px !important;
    height: auto !important;
    cursor: pointer;
    #body-styles2;

    & > button + button {
        margin-left: 20px;
    }

    &-disabled {
        color: @disabled-text-color !important;
        border-color: @border-color !important;
        background: @disabled !important;
        text-shadow: none !important;
        box-shadow: none !important;
        cursor: not-allowed !important;
    }
}


