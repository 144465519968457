@import "@/stylesheets/global.less";
.amp-assets-info {
    border-bottom: 1px solid #E0E0E0;
    padding-bottom: 16px;
    margin-bottom: 24px;
    margin-top: -8px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    .collapse-content {
        .to-vw(520);
    }
}

.amp-assets-info-split-task {
    .amp-text-over-ellipsis{
        // .to-vw(400);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .amp-assets-info-split-task-status {
        height: 22px;
        font-size: 13px;
        line-height: 22px;
        padding: 3px 6px;
        border-radius: 2px;
        color: #FFF;
    }
}

.amp-assets-info-item-title {
    min-width: 120px;
}
