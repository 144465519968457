

::-webkit-scrollbar {
  width: 4px; /* 纵向滚动条*/
  height: 4px; /* 横向滚动条 */
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: transparent;
  opacity: 0;
  transition: opacity 120ms ease-out;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #e0e0e0 !important;
  border-radius: 3px;
  background-clip: padding-box;
  transition: 0.3s background-color;
}

::-webkit--corner {
  background-color: transparent;
}
::-webkit-scrollbar-button {
  height: 12px;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: #C2C2C2 !important;
  width: 5px !important;
  border-radius: 10px !important;
}

.rc-virtual-list-scrollbar {
  width: 4px !important;
}


.overflow-hidden {
  overflow: hidden;
}
