@import "@/stylesheets/global.less";
.amps_fall_view_container{
    align-content: flex-start;
    background-color: #000;
    padding-top: 8px;
    height: calc(100Vh - 160px);
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .init_loading{
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        color: #484848;
        text-align: center;
    }
    .fall_footer{
        position: relative;
        height: 20px;
        width: 100%;
        flex: 0 0 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 10px;
        .fall_footer_text{
            color: #fff;
            font-size: 14px;
            text-align: center;
        }
    }
}
