.icon-rec {
    svg {
        height: 20px;
        width: 56px;
    }
}

.icon-rec-none {
    svg {
        height: 20px;
        width: 56px;
    }

    margin-right: -4px;
}

.icon-20 {
    svg {
        height: 20px;
        width: 20px;
    }
}

.icon-16 {
    svg {
        height: 16px;
        width: 16px;
    }
}

.icon-18 {
    svg {
        height: 18px;
        width: 18px;
    }
}

.icon-8 {
    svg {
        height: 8px;
        width: 8px;
    }
}

.icon-12 {
    svg {
        height: 12px;
        width: 12px;
    }
}

.icon-14 {
    svg {
        height: 14px;
        width: 14px;
    }
}

.icon-16 {
    svg {
        height: 16px;
        width: 16px;
    }
}

.icon-23 {
    svg {
        height: 23px;
        width: 23px;
    }
}

.icon-24 {
    svg {
        height: 24px;
        width: 24px;
    }
}

.icon-30 {
    svg {
        height: 30px;
        width: 30px;
    }
}

.icon-none {
    display: none;
}

.task-priority-icon {
    svg {
        height: 20px;
        width: 20px;
    }
}

.activity-icon {
    svg {
        width: 48px;
        height: 30px;
    }
}

.icon-small {
    svg {
        width: 16px;
        height: 16px;
    }
}

.icon-32 {
    svg {
        height: 32px;
        width: 32px;
    }
}

.icon-new {
    svg {
        width: 24px;
        height: 12px;
    }
}

.icon-27 {
    svg {
        height: 27px;
        width: 27px;
    }
}

.icon-hover-main {
    &:hover {
        color: #29c1b9;
    }
}
