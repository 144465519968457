@import "@/stylesheets/global.less";
.amps-task-list {
  width: 364px;
  background: #f5f6fa;
  .list-body {
    width: 100%;
  }
  .list-state {
    height: 73px;
    padding: 0 32px;

    .state-plan {
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #19233c;
    }
    .state-number {
    }
    .number-body {
      margin-left: 16px;
      .body-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #70d9d9;
      }
      .body-number {
        margin-left: 4px;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #19233c;
      }
    }
  }
  .state-shadow {
    position: relative;
    z-index: 9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  }

  .list-bottom {
    width: 356px;
    height: 718px;
    margin-left: 4px;
    .list-bottom-body {
      overflow-y: scroll;
      overflow-x: hidden;
      min-height: 700px;
      position: relative;
      width: 100%;

      .list-create {
        width: 300px;
        // margin-top: 24px;
        height: 38px;
        border: 1px dashed #bfc5d1;
        font-family: Noto Sans SC;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #29c1b9;
        margin-left: 32px;
      }
      .list-tasks {
        margin-top: 12px;
        position: relative;
        width: 100%;
        height: calc(100% - 50px);
      }
      .list-loading {
        min-height: 718px;
      }
    }
    .list-create:hover {
      cursor: pointer;
    }
  }

  /* 设置滚动条的样式 */
  .list-bottom-body::-webkit-scrollbar {
    width: 4px;
    display: block !important;
  }
}

.ant-tooltip-inner {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    color: #FFFFFF;
}

.ant-tooltip-arrow {
    display: none !important;
}
