@import "@/stylesheets/global.less";
.amp-topic-description-details-render {
    width: 100%;

    .amp-descript {
        display: flex;
        margin-bottom: 14px;
    }

    .amp-createInfo {
        display: flex;
        width: 100%;

        .amp-createTime {
            display: flex;
            width: 50%;
            margin-bottom: 14px;
        }

        .amp-createUser {
            display: flex;
            width: 50%;
            margin-bottom: 14px;
        }
    }

    .amp-topicInfo {
        display: flex;
        width: 100%;

        .amp-topicOutline {
            display: flex;
            width: 50%;
            margin-bottom: 14px;
        }

        .amp-topicTheme {
            display: flex;
            width: 50%;
            margin-bottom: 14px;
        }
    }

    .amp-topicAddress {
        display: flex;
        width: 100%;
    }

    .amp-label {
        align-items: center;
        min-width: 57px;
        height: 27px;
        margin-right: 8px;
        color: #000;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        line-height: 27px;
    }

    .amp-content {
        align-items: center;
        width: calc(100% - 57px);
        overflow-x: hidden;
        color: #595959;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        line-height: 27px;
    }

    .amp-info-avatar {
        display: flex;

        .amp-info-avatar-details {
            margin-right: 6px;
            color: #595959;
            font-weight: 400;
            font-size: 14px;
            font-style: normal;
            line-height: 27px;
        }
    }

    .amp-scene {
        display: flex;

        .amp-scene-item {
            flex-wrap: wrap;
            height: 27px;
            margin-right: 8px;
            padding: 0 7px;
            overflow: hidden;
            color: #fa8c16;
            font-size: 12px;
            background-color: #fff7e6;
            border: 1px solid #ffd591;
            border-radius: 4px;
        }
    }

    .amp-address {
        display: flex;

        .amp-address-item {
            position: relative;
            margin-right: 8px;
        }
    }

    .amp-address-item-icon {
        position: absolute;
    }

    .amp-address-item-info {
        padding-left: 20px;
    }

    .amp-mg-r-8 {
        margin-right: 8px;
    }

    .amp-mg-r-4 {
        margin-right: 4px;
    }

    .amp-bolder {
        font-weight: 700;
    }
}
