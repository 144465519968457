@import "@/stylesheets/global.less";
.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.flex-justify-end {
    display: flex;
    justify-content: flex-end;
}

.flex-align-end {
    display: flex;
    align-items: flex-end;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.flex-column-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

.flex-justify-space-around {
    display: flex;
    justify-content: space-around;
}

.flex-item-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.one-show {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.two-show {
    overflow: hidden;
    display: -webkit-box;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
