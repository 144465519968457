@import "@/stylesheets/global.less";
@import "../../stylesheets/theme";

.amps-dialog{
    .ant-modal-header{
        border-bottom: none;
        .ant-modal-title{
            #h3-styles;
        }
    }

    .ant-modal-cancel{
        font-weight: 600 !important;
    }
    .ant-modal-footer{
        border-top: none;
        text-align: left;
    }
}
