@import "@/stylesheets/global.less";
.amp-scene-description {
    float: left;
    width: calc(70% - 4px);
    height: 100%;
    margin-right: 4px;
    padding-right: 9px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: block;
    }

    .amp-scene-description-slideImage {
        height: 350px;
    }

    .amp-scene-description-details {
        margin-top: 16px;
        margin-bottom: 8px;
    }
}
