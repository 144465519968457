@import "@/stylesheets/global.less";
.amp-create-topic-modal {
    .ant-modal-body {
        position: relative;
        padding: 0;
    }

    .amp-create-topic-modal-body {
        min-height: 500px;
        max-height: 70vh;
        padding: 24px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: inline;
        }
    }

    .amp-create-topic-add {
        display: flex;
        flex-flow: column;
        padding-bottom: 80px;
    }

    .amp-create-topic-form {
        flex: 1;
    }

    .amp-create-topic-label {
        color: #333;
        font-weight: normal;
        font-size: 14px;
    }

    .amp-create-topic-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 24px;
        background: #fff;
        border-radius: 0 0 6px 6px;
        box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    }

    .ant-modal-title {
        font-size: 16px;
    }

    /* stylelint-disable-next-line selector-max-type */
    .ant-form-large .ant-form-item-label > label {
        height: 20px;
        line-height: 20px;
    }
}
