@import "@/stylesheets/global.less";
/* stylelint-disable declaration-no-important */
.amps-scene-info {
    float: right;
    width: 30%;
    height: 100%;
    padding: 0 11px 16px 16px;
    overflow-y: scroll;
    border-left: 1px solid #e8e8e8;

    .amp-scene-history {
        .ant-timeline-item-content {
            width: calc(25vw - 100px) !important;
        }
    }

    .amp-scene-history-title {
        position: relative;
        color: #000;
        font-weight: 500;
        size: 14px;

        .amp-scene-history-title-icon {
            position: absolute;
        }

        .amp-scene-history-title-content {
            margin-left: 28px;
        }
    }

    .amp-annotation-header-title-icon {
        position: absolute;
    }

    .amp-annotation-header-operation {
        position: absolute;
        right: 0;
        padding-left: 16px;
        border-left: 1px solid #e8e8e8;
    }

    .amp-annotation-header-title-content {
        align-items: center;
        margin-left: 32px;
        color: #000;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .amp-add-annotation {
        padding: 2px 9px;
        color: #595959;
        font-weight: 400;
        font-size: 12px;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        cursor: pointer;
    }

    .amp-scene-annotation {
        position: relative;
    }

    .amp-scene-relate-title {
        position: relative;
        color: #000;
        font-weight: 500;
        size: 14px;

        .amp-scene-relate-title-icon {
            position: absolute;
        }

        .amp-scene-relate-title-content {
            margin-left: 28px;
        }
    }

    &::-webkit-scrollbar {
        display: block;
    }
}
