@import "@/stylesheets/global.less";
.amp-details-header {
    height: 32px;
    line-height: 32px;
    vertical-align: middle;

    .amp-details-title {
        display: inline-block;
        align-items: center;
        max-width: 90%;
        height: 32px;
        overflow: hidden;
        color: #262626;
        font-weight: 500;
        font-size: 24px;
        // font-family: "PingFang SC";
        // font-family: "PingFang SC";
        font-style: normal;
        line-height: 32px;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: bottom;
    }

    .amp-details-id {
        display: inline-block;
        align-items: center;
        height: 24px;
        margin-left: 16px;
        color: #000;
        font-weight: 400;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    }
}
