@import "@/stylesheets/global.less";
.amp-work-progress {
    width: 100%;

    .amp-work-progress-title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 18px;
    }

    .amp-work-progress-title-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-color: #29c1b9;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
    }

    .amp-flex-row-start-center {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .amp-flex-row-between-center {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .amp-work-card {
        min-width: 280px;
        margin-right: 30px;
        margin-bottom: 16px;
        padding: 16px 24px;
        background: #fff;
        border: 1px solid rgba(229, 229, 229, 0.25);
        border-radius: 6px;
        box-shadow: 0 8px 20px rgba(229, 229, 229, 0.5);
    }

    .amp-fs-16 {
        font-size: 16px;
    }

    .amp-fs-24 {
        font-size: 24px;
    }

    .amp-mb-16 {
        margin-bottom: 16px;
    }

    .amp-mb-32 {
        margin-bottom: 32px;
    }

    .amp-mb-40 {
        margin-bottom: 40px;
    }

    .amp-mr-10 {
        margin-right: 10px;
    }

    .amp-c-e25 {
        color: #1c1e25;
    }

    .amp-pb-16 {
        padding-bottom: 16px;
    }

    .amp-minh-24 {
        min-height: 24px;
    }

    .amp-work-main-font {
        min-height: 30px;
        color: #29c1b9;
        font-size: 28px;
    }

    .amp-task-num-card {
        position: relative;
        width: 240px;
        padding: 16px 24px;

        &::after {
            position: absolute;
            top: 50%;
            left: 0;
            width: 1px;
            height: 40px;
            margin-top: -20px;
            background-color: #c5c7d4;
            content: "";
        }
    }

    .amp-work-progress-from {
        display: flex;
        align-items: center;
    }

    .amp-work-progress-from-title {
        margin-right: 10px;
        margin-bottom: 24px;
        font-size: 14px;
    }

    .amp-work-progress-project-type {
        display: inline-block;
        padding: 0 8px;
        color: #29c1b9;
        font-size: 14px;
        line-height: 22px;
        border: 1px solid #29c1b9;
        border-radius: 6px;
    }

    .amp-work-progress-task-type {
        display: inline-block;
        padding: 0 6px;
        color: #fff;
        font-size: 12px;
        line-height: 22px;
        border-radius: 2px;
    }
}
