@font-face {
    font-family: 'NotoSans';
    src: url( './NotoSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'NotoSans';
    src: url( './NotoSans-Regular.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'NotoSans';
    src: url( './NotoSans-Regular.ttf');
    font-weight: 400;
}


