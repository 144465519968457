@import "@/stylesheets/global.less";
.task-plan-drawer {
    position: relative !important;
    right: 0;
    bottom: 0 !important;
    height: 100% !important;

    .ant-drawer-body {
        top: 0 !important;
        padding: 0 !important;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
}

.removeMargin {
    .ant-form-item {
        margin-bottom: 0px;
    }
}

.task-plan-model {
    box-sizing: border-box;
    width: 100%;
    max-height: 647px;
    overflow: scroll;
    background-color: #fff;

    .task-form {
        .task-form-col-2 {
            min-height: 4.375vw !important;
            overflow: hidden !important;
        }
    }
}

.task-plan-title {
    height: 20px;
    color: #19233c;
    font-weight: 700;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-style: normal;
    line-height: 20px;
}

.task-plan-content {
    .bold {
        margin-bottom: 8px !important;
        font-weight: normal !important;
    }

    .removeMg {
        margin-top: 0 !important;
    }
}

.div-appear {
    transform: translateX(904px);
    transition: 5s;
}

.div-spread {
    transform: translateX(0);
    transition: 5s;
}

.error-reason {
    height: 20px;
    color: #ec3b3b;
    font-weight: normal;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-style: normal;
    line-height: 20px;
    cursor: pointer;
}

.amp-task-form {
    display: flex;
    flex-wrap: wrap;
}

.amp-drawer-ant-row {
    display: flex;
    width: 40%;

    .ant-row {
        display: table;
        flex: 0 1 40%;
    }
}

.amp-task-edit {
    display: flex;
    flex-direction: column;
}

.amp-assets-info-items {
    display: flex;
    flex: 0 1 40%;

}

.amp-assets-info-item {
    display: flex;
}

.amp-w-50 {
    flex: 0 1 50%;
}

.amp-w-100 {
    flex: 0 1 100%;
}

.amp-assets-info-label {
    text-align: left;
    word-break: break-all;
}

.amp-task-package-region {
    margin-bottom: 32px;
}

.amp-task-package-process {
    display: flex;
    margin-top: 10px;
}

.amp-task-package-process-title {
    display: flex;
    flex: 0 1 38%;
}

.tmp-info-images-list {
    &-title {
        display: flex;
        padding: 8px 0;
        align-items: center;
        &-statics {
            display: flex;
            &-desc {
                margin-right: 24px;
                display: flex;
                color: #19233C;
                font-size: 14px;
                &-label {
                    font-weight: 300;
                    margin-right: 5px;
                }

                &-value {
                    font-weight: 400;
                }
            }
        }

        &-form {
            display: flex;
            flex: 1;
            &-select {
                flex: 1;
                max-width: 175px !important;
                height: 36px !important;
                margin-right: 14px !important;
                .ant-select-selector{
                    height: 36px !important;

                }
            }
            &-input{
                flex: 1;

                max-width: 175px !important;
                height: 36px !important;
            }
        }
    }

    &-container {
        //overscroll-behavior: contain;
        max-height: 350px;
        min-height: 150px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &-init-loading{
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
        }
        &-button-loading{
            position: relative;
            width: 100%;
            display: flex;
            justify-content:center
        }
    }
}
