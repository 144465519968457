.to-vw (@px, @attr: width) {
    @vw: (@px / 1920) * 100;

    @{attr}: ~"@{vw}vw";
}

.to-vh(@px, @attr: height) {
    @vh: (@px / 1080) * 100;

    @{attr}: ~"@{vh}vh";
}
@media screen and (max-width:900px){
    .header-logo{
        display:none !important;
    }
}

@media screen and (max-width:900px){
    .header-tabs{
        width: 100% !important;
        justify-content: space-between !important;
    }
}
