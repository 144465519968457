@import "@/stylesheets/global.less";
.amp-topic-scene-scroll {
    .amp-scene-wrap-line {
        display: flex;
    }

    .amp-scene-wrap-line-item {
        width: 25%;
        padding: 8px;
        overflow: hidden;
    }

    .amp-scene-wrap-line-item-wrap {
        border: 1px solid #e8e8e8;
        cursor: pointer;

        &:hover {
            background-color: #f0fffc;
            border-color: #29c1b9;
        }
    }

    .amp-scene-wrap-line-item-wrap-pic {
        position: relative;
        padding-top: 61.8%;
        overflow: hidden;
    }

    .ant-image,
    .amp-scene-item-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .amp-scene-wrap-line-item-content {
        padding: 8px;
    }

    .amp-scene-item-content-name {
        margin-bottom: 8px;
        overflow: hidden;
        color: #000;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .amp-scene-item-content-number {
        margin-right: 8px;
        color: #19233c;
        font-size: 12px;
        line-height: 22px;
    }

    .amp-scene-item-content-tag {
        padding: 0 8px;
        overflow: hidden;
        color: #999;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
    }
}
