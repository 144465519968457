@import "@/stylesheets/global.less";
.amp-topic-scene-modal {
    .ant-modal-title {
        font-size: 16px;
    }

    .amp-topic-scene-modal-tips {
        margin-bottom: 16px;
        color: #595959;
        font-size: 14px;
        line-height: 22px;
    }

    .amp-topic-scene-modal-body {
        max-height: 70vh;
        overflow-y: auto;
    }

    .amp-create-topic-label {
        color: #333;
        font-weight: normal;
        font-size: 14px;
    }

    .amp-topic-scene-modal-scroll {
        width: 100%;
        padding: 5px;
        box-shadow: inset 0 -1px 10px rgba(0, 0, 0, 0.1);
    }

    .amp-scene-scroll-modal-line {
        display: flex;
    }

    .amp-scene-scroll-modal-line-item {
        width: 16.6%;
        padding: 4px;
        overflow: hidden;
    }

    .amp-scene-modal-item-wrap {
        border: 1px solid #e8e8e8;
        cursor: pointer;
    }

    .amp-scene-modal-item-wrap-pic {
        position: relative;
        padding-top: 61.8%;
    }

    .ant-image,
    .amp-scene-item-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .amp-scene-item-content {
        padding: 8px;
    }

    .amp-scene-item-content-name {
        margin-bottom: 4px;
        overflow: hidden;
        color: #000;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .amp-scene-item-content-number {
        margin-right: 8px;
        color: #19233c;
        font-size: 12px;
        line-height: 20px;
    }

    .amp-scene-item-content-tag {
        padding: 0 8px;
        overflow: hidden;
        color: #999;
        font-size: 12px;
        line-height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
    }

    .amp-scene-item-check {
        position: absolute;
        top: 6px;
        right: 6px;
        z-index: 1;
    }

    .amp-scene-item-checked {
        border-color: #29c1b9;
    }

    /* stylelint-disable-next-line selector-max-type */
    .ant-form-large .ant-form-item-label > label {
        height: 20px;
        line-height: 20px;
    }

    .ant-image-error {
        background-color: #000;

        .ant-image-img {
            object-fit: none;
        }
    }
}
