@import "@/stylesheets/global.less";
.amps-plan-task-img {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 86px;
    background: #fff;
    border: 0.5px solid #bfc5d1;
    cursor: pointer;

    .task-img-body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 68px;
        height: 60px;
        margin-top: 6px;
        background: #000;

        .assets-img {
            width: 35.77px;
            height: 51.35px;
        }
    }

    .task-text {
        margin-top: 4px;
        font-weight: 300;
        font-size: 12px;
        font-family: Noto Sans SC;
        font-style: normal;
    }

    .border-pass {
        border: 1px solid #29c1b9;
    }

    .border-reject {
        border: 1px solid #fb3152;
    }

    .border-invalid {
        border: 1px solid #bfc5d1;
    }

    .background-invalid {
        background: rgba(0, 0, 0, 0.6);
    }

    .text-invalid {
        opacity: 0.6;
    }

    .assets-none {
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);

        .icon-body {
            width: 100%;
            height: 100%;
        }
    }
}

.amps-plan-task-img:hover .assets-hover {
    display: block;
}

.maskImage {
    .preview-task-info-body {
        position: absolute;
        top: 100px;
        left: 150px;

        .preview-info {
            display: flex;
            margin-bottom: 4px;
            color: #fff;
            font-weight: 300;
            font-size: 14px;
            font-family: Noto Sans SC;
            font-style: normal;
            line-height: 20px;
            text-align: left;

            span {
                display: block;
                width: 50px;
                margin-right: 10px;
            }
        }
    }
}

#taskMasBody {
    background-color: rgba(0, 0, 0) !important;
}

.amps-no-data{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
}
