@black: #000000;
@light: #FFFFFFF;
// 系统主要颜色配置
@primary-color: #29C1B9;
@secondary-color: #B93D2D;
@secondary-color2: #76CA98;
@secondary-color3: #F4C647;
@disabled: #f5f5f5;
// 文字颜色配置
@primary-text-color: #19233C;
@secondary-text-color: #ffffff;
@secondary-text-color2: #F7F8FA;
@disabled-text-color: #00000040;
// 背景颜色
@primary-background-color: #ffffff;
// @secondary-background-color: #F5F6FA;
@secondary-background-color: rgba(0, 0, 0, 0.04);
// 边框颜色
@border-color: #D9D9D9;
@border-color2: #E8E8E8;

// 阴影的颜色
@primary-shadow-color: rgba(0, 0, 0, 0.05);

// spacers=> margin, padding
@spacer: 8px;
@spacers: @spacer * 1, @spacer * 2, @spacer * 3, @spacer * 4, @spacer * 5;

each(@spacers, {

  // margin
  .m-@{index} {
    margin: @value !important;
  }

  .mt-@{index} {
    margin-top: @value !important;
  }

  .mr-@{index} {
    margin-right: @value !important;
  }

  .mb-@{index} {
    margin-bottom: @value !important;
  }

  .ml-@{index} {
    margin-left: @value !important;

  }

  .mx-@{index} {
    margin-left: @value !important;
    margin-right: @value !important;
  }

  .my-@{index} {
    margin-top: @value !important;
    margin-bottom: @value !important;
  }

  // padding
  .p-@{index} {
    padding: @value !important;
  }

  .pt-@{index} {
    padding-top: @value !important;
  }

  .pr-@{index} {
    padding-right: @value !important;

  }

  .pb-@{index} {
    padding-bottom: @value !important;

  }

  .pl-@{index} {
    padding-left: @value !important;

  }

  .px-@{index} {
    padding-left: @value !important;
    padding-right: @value !important;

  }

  .py-@{index} {
    padding-top: @value !important;
    padding-bottom: @value !important;
  }
});

@display: flex, block, inline, inline-flex, inline-block;
each(@display, {
  .d-@{value} {
    display: @value;
  }
});

.flex-0{
  flex: 0;
}
.flex-1{
  flex: 1;
}
.d-flex-col {
  display: flex;
  flex-direction: column;
}

@wraps: wrap, nowrap, wrap-reverse;

each(@wraps, {
  .flex-@{value} {
    flex-wrap: @value;
  }
});

@aligns: {
  start: flex-start;
  end: flex-end;
  center: center;
  stretch: stretch;
};

each(@aligns, {
  .align-items-@{key} {
    align-items: @value;
  }

  .justify-content-@{key} {
    justify-content: @value
  }
})

@justifys: {
  start: flex-start;
  end: flex-end;
  center: center;
  between: space-between;
  around: space-around;
  evenly: space-evenly;
}

each(@justifys, {
  .justify-content-@{key} {
    justify-content: @value
  }
})

// 鼠标手势

@cursors: {
  pointer: pointer;
  disabled: not-allowed;
}

each(@cursors, {
  .cursor-@{key} {
    cursor: @value;
  }
})

// 用户禁止选择文本

.user-select-none {
  user-select: none;
}
.float-left{
    float: left;
}
.float-right{
    float: right;
}

// 字体大小
#h1-styles {
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
}

#h2-styles {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
}

#h3-styles {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}

#subtitle-styles1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

#subtitle-styles2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

#body-styles1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

#body-styles2 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

#body-caption {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
