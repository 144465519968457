@import "@/stylesheets/global.less";
@import "../../../stylesheets/theme";

.amps-number-assets{
  .card{
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
  }
  .tips{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: @primary-color;
  }
  .tips2{
    padding: 8px;
    background-color: #F4F5F7;
    .label{

    }
    .value{
      color: #595959
    }
  }
}
