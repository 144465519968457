@import "@/stylesheets/global.less";
.amp-topic-scene-manage-page {
    width: 100%;
    height: calc(100vh - 68px);

    .amp-scene-manage-content {
        display: flex;
        align-items: stretch;
        width: 100%;
        height: 100%;
    }

    .amp-manage-content-topic-wrap {
        width: 20%;
        max-width: 350px;
        padding: 14px 0 24px;
    }

    .amp-manage-content-topic-wrap-border {
        height: 100%;
        padding-right: 4px;
        border: 1px solid #e8e8e8;
    }

    .amp-manage-content-scene-wrap {
        display: flex;
        flex: 1;
        flex-flow: column;
        align-items: stretch;
        min-width: 0;
        margin-left: 8px;
        padding-top: 14px;
    }

    .amp-scene-wrap-title {
        margin-top: -8px;
        margin-bottom: 8px;
        padding: 0 8px;
        color: #262626;
        font-size: 24px;
        line-height: 32px;
    }

    .amp-scene-wrap-search-form {
        display: flex;
        padding: 0 8px;
    }

    .amp-scene-wrap-search-filter {
        flex: 1;
        width: 90%;
    }

    .ant-select-selection-placeholder {
        width: 1px;
    }

    .ant-image-error {
        background-color: #000;

        .ant-image-img {
            object-fit: none;
        }
    }
}
