@import "@/stylesheets/global.less";
.amp-task-info-title{
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .amp-task-info-status{
        width: 80px;
    }
}
