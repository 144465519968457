@import "@/stylesheets/global.less";
/* stylelint-disable declaration-no-important */
.amps-drawer-children {
    width: 50%;
    overflow: hidden;

    .amp-drawer-children-operator {
        display: inline-block;
        width: 50%;
        padding-right: 10%;
    }

    .amp-drawer-children-next {
        display: inline-block;
        width: 50%;
        padding-right: 10%;
    }

    .amp-drawer-children-comment {
        margin-top: 24px;
    }

    .amp-drawer-title {
        color: #000;
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
    }

    .amp-h-100 {
        height: 100px !important;
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}
