@import "@/stylesheets/global.less";
.amps-no-data {
  position: relative;
  width: 100%;
  height: 100%;
  .no-data-body {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .data-img {
    width: 82px;
    height: 76px;
  }
  .data-text {
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #8b94a5;
  }
}
