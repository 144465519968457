@import "@/stylesheets/global.less";
/* stylelint-disable declaration-no-important */
.amps-add-annotation {
    .ant-modal-title {
        color: #000;
        font-weight: 400;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    }

    .ant-modal-body {
        padding: 16px 24px 0;
    }

    .amp-w-50 {
        width: 50%;
    }

    .amp-mg-b-10 {
        margin-bottom: 10px;
    }

    .amp-h-86 {
        height: 86px !important;
    }

    .amp-annotation-item {
        .amp-annotation-item-title {
            display: inline-block;
            color: #000;
            font-weight: 400;
            font-size: 14px;
            font-style: normal;
            line-height: 22px;
        }
    }

    .amp-annotation-operator {
        text-align: right;
    }

    .amp-mg-r-12 {
        margin-right: 12px;
    }
}
