@import "@/stylesheets/global.less";
.amp-tasks-package {
    overflow-x: hidden;
    height: 859px;
    padding-bottom: 0 !important;
    .right-create-tasks {
        //   position: relative;
        width: 904px;
        position: relative;
        .tasks-top-blank {
            width: 904px;
            height: 24px;
            background: #ffffff;
        }
        .blank-shadow {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        }
        .tasks-bottom-button {
            width: 904px;
               //z-index: 9;
            position: absolute;
            bottom: 0;
            background: #ffffff;
        }
        .bottom-shadow {
            box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
        }
        .ant-drawer-body {
            position: static !important;
            padding: 30px 32px 20px !important;
        }
    }
    .ant-modal-header {
        .ant-modal-title {
            font-family: Noto Sans SC;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            display: flex;
            align-items: center;
            color: #19233C;
        }
    }
    .ant-modal-close {
        right: 10px;
    }
}
