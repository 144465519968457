@import "@/stylesheets/global.less";
.amps-delete-modal {
    padding: 20px;
    font-size: 16px;

    .amp-delete-modal-title {
        margin-bottom: 40px;
    }

    .amp-delete-modal-footer {
        margin-top: 40px;
    }
}
