@import "@/stylesheets/global.less";
.amp-recommended-color {
    display: flex;
    flex-wrap: wrap;

    .amp-recommended-color-block {
        height: 32px;
        width: 32px;
        border: 1px solid #E8E8E8;
        margin-right: 4px;
        margin-bottom: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
    }

    .amp-recommended-color-pointer {
        cursor: pointer;
    }

    .amp-recommended-color-disabled {
        cursor: not-allowed;
    }
}
