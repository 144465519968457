@import "@/stylesheets/global.less";
.amp-fixed-select {
    height: 40px;
    border-radius: 4px;
    width: 100%;
    min-width: 150px;
    display: block!important;

    .ant-select-selector{
        height: 40px!important;
    }
    .ant-select-selection-item{
        height: 38px !important;
    }

}
