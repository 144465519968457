.settings {
    background: #fff !important;
    height: 100vh;
    .ant-menu-inline {
        height: 100%;
    }
    .ant-menu-item {
        text-align: center !important;
    }
}
.settings-user-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add-user-btn {
    position: absolute;
    right: 0px;
    top: 0;
}

.ant-layout-sider {
    background: #fff !important;
}

.edit-user-select {
    .ant-select-selector {
        height: 40px !important;
        border-radius: 2px;
    }
    .ant-select-selection-item, .ant-select-selection-placeholder {
        line-height: 40px !important;
    }
    
}
.cursor-default {
    cursor: default !important;
}

.user-form {
    position: relative;  
  .user-item-input {
    width: 260px;
    display: inline-block;
    .normal-input {
        height: 40px; 
    }
    .ant-input-disabled {
        background: #fff !important;;
        cursor:pointer;
        color: #000 !important;
    }
  }

.edit-user-text-btn {
    position: absolute;
    width: 28px;
    height: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 0px !important;
    top: 48px;
    left: 272px;
    color: #66B2BB;
}  
}


.settings-user-content {
  padding-top: 20px ;
}

.reset-filter {
    display: inline-block;
    font-weight: 700;
    height: 40px;
    .to-vw(40, line-height);
    cursor: pointer;
    padding-left: 20px;
}

.dialog-del-footer {
    margin-top: 68px;
}

.user-dialog-footer {
    bottom: 48px;
    left: 0px;
    padding-left: 60px;
    position: absolute;
}
.editUser, .editRole {
    .ant-modal-body {
        height: 758px;
    }
}
.tox-statusbar {
    display: none !important;
}