.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: pointer !important;
}

.project-filters {
  display: flex;
  font-size: 14px !important;
  width: 100%;
  flex-flow: row wrap;
  position: relative;
}

.ant-select-selector {
  height: 40px;
  line-height: 35px;
}

.ant-select-item {
}

.reset-filter {
  font-weight: 700;
  height: 40px;
  cursor: pointer;
}

.waterfall-button{
  position: absolute;
  right: 0;
}

.role-filter {
  display: flex;
  font-size: 14px !important;
}
.ant-select-multiple .ant-select-selection-item {
  background: transparent !important;
  border: none !important;
}

.filter-input {
  .to-vw(230) !important;
  height: 40px !important;
  .ant-picker-separator {
    display: none;
  }
  .ant-picker-input {
    padding-left: 3px !important;
  }
}

.filter-input-focused {
  &:extend(.filter-input);
  .ant-picker-separator {
    display: inline !important;
  }
  .ant-picker-input {
    padding-left: 3px !important;
  }
}

.ant-picker-range-separator {
  padding: 0 6px 0 0;
}

.ant-select-selection-item {
  padding-left: 3px !important;
}

.total .ant-form-item .ant-input-number-handler-wrap {
  display: none;
}

.total .ant-input-number-input {
  padding: 0 !important;
}