@import "@/stylesheets/global.less";
.amps-scene-details-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .amp-scene-details-content-main {
        height: calc(100% - 80px);
    }
}
