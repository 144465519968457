@import "@/stylesheets/global.less";
/* stylelint-disable declaration-no-important */
.amps-history-container {
    .amps-history-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .amp-history-title {
        margin-bottom: 8px;
        font-size: 12px;
    }

    .ant-timeline-item-content {
        width: calc(30vw - 100px) !important;
    }

    .ant-timeline-item-label {
        margin-top: 4px;
        color: #595959;
        font-size: 12px;
    }

    .ant-timeline-item-last > .ant-timeline-item-tail {
        display: block;
    }

    .ant-timeline-item {
        width: 150px;
        margin-bottom: 16px;
    }

    .amp-history-label {
        color: #595959;
    }
}
