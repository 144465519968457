@import "@/stylesheets/global.less";
.amp-select-theme {
    .amp-select-theme-menu {
        min-width: auto;
    }
}

.amp-cascader-menus {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    .amp-cascader-menu {
        flex-grow: 1;
        min-width: 111px;
        height: 180px;
        margin: -4px 0;
        padding: 4px 0;
        overflow: auto;
        vertical-align: top;
        list-style: none;
        border-right: 1px solid rgba(0, 0, 0, .06);
    }
    .amp-cascader-menu-item{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 5px 12px;
        overflow: hidden;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: all .3s;
    }
    .amp-cascader-menu-item-active{
        font-weight: bold;
        background-color: #e6f7ff;
    }
    .amp-cascader-menu-item-content{
        flex: auto;
    }
    .amp-cascader-menu-item-icon{
        margin-left: 4px;
        font-size: 12px;
    }
}
