.project-title {
    min-height: 54px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 2px solid #f2f2f2;
    cursor: default;
    justify-content: space-between;
}

.project-title-left {
    display: flex;
    line-height: 24px;
    box-sizing: border-box;
}

.project-title-right {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.project-sub-title {
    display: flex;
    height: 70px;
    line-height: 70px;
    justify-content: space-between;
    box-sizing: border-box;
}

.project-title-filter {
    border: 1px solid red;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    float: left;
    flex-wrap: wrap;
    height: 70px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.98);;
    overflow: hidden;
    z-index: 50;
    position: relative;
    transition: height 0.3s, width 10s;

    .filter-box {
        width: 65.2vw;
    }

    .operator-box {
        display: flex;
        align-items: center;
        position: relative;
        top: -35px;
        height: 70px;

        .toggle-box {
            float: left;
            width: 40px;
            height: 40px;
            border: 1px solid #D9D9D9;
            text-align: center;
            border-radius: 2px;
            cursor: pointer;

            span {
                top: -50%;
                transform: translateY(10px);
                position: relative;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .toggle-box:hover {
            border-color: #4487DC;

            svg {
                color: #4487DC;
            }
        }
    }
}

.selectEmpty .ant-empty-normal {
    margin: 8px !important;
}

.project-sub-right {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    float: right;

    .ant-btn {
        padding: 0px;
    }
}

.asset-add-title-drawer {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 43px;
}

.asset-drawer-select {
    width: 210px;
    font-weight: 500;

    .ant-select-selector {
        height: 40px !important;
        border-radius: 2px;
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
        line-height: 40px !important;
    }

}

.asset-drawer-text {
    .ant-form-item-label > label {
        font-size: 14px !important;
        font-weight: 500;
    }
}


.asset-make-footer {
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
}

.asset-drawer-footer,
.asset-make-footer,
.asset-discard-footer {
    height: 80px;
    line-height: 80px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding-left: 60px;
    width: 100%;
    z-index: 400;
    background-color: white;

    .ant-form-item {
        margin-bottom: 0px;
        height: 80px;
        line-height: 80px;
    }
}

.showCount {
    .ant-form-item-control-input-content {
        height: 80px;
    }
}

.asset-history-drawer {
    .ant-drawer-body {
        // padding-top: 18px !important;
    }

    .title-pos {
        position: relative !important;
    }

    .title {
        position: fixed;
        justify-content: space-between;

        .right {
            // float: right;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #4C4C4C;
        }
    }
}

.history-drawer-action-detail {
    .ant-row {
        margin-bottom: 17px;
    }

    .label {
        display: inline-block;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        width: 56px;
    }

    .value {
        display: inline-block;
        color: #000000;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-left: 22px;
    }
}


.history-timeline-IconHeader {
    margin: -35px 0px 11px 0px;
}

.drawer-input {
    width: 210px;

    .normal-input {
        height: 40px;
    }
}

.drawer-input-disabled {
    width: 210px;
    font-weight: 500;

    .ant-row {
        display: none;
    }

    .normal-input {
        height: 40px;
    }

    .ant-input-disabled {
        background: #fff;
        cursor: pointer;
        color: #000 !important;
    }

}


.to-void-drawer {
    .ant-drawer-bottom {
        z-index: 500 !important;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
    }

    .tips {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #9B9B9B;
        margin-left: 8px;
    }
}

.task-detail-people-section {
    display: flex;
    align-items: center;
    margin-right: 32px;
}

.createdForm {
    .ant-form-item-label > label {
        font-size: 18px !important;
        font-weight: 500 !important;
    }

    .idContent {
        font-weight: normal;
        font-size: 12px;
        color: #6D6D6D !important;
    }
}

.bottom-drawer-disappear {
    .ant-drawer-bottom {
        z-index: 0 !important;
    }
}

img {
    object-fit: scale-down;
}

.amps-filter-section {
    height: 70px;
    position: relative;
    flex: 1;

    &-container-open {
        height: 140px;
        box-shadow: 0px 10px 12px rgba(124, 124, 124, 0.3);
    }
    &-container-close {
        height: 70px;

    }
    &-container {
        left: 0;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        transition: height 0.5s ease;
        background-color: #fff;
        width: calc(100% + 100px);
        margin: 0 -50px;
        position: absolute;
        padding: 0 50px;
        &-form {
            flex: 1;

            &-item {
                width: 100%;
            }
        }

        &-buttons {
            flex: 0 0 120px;
            width: 120px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-switch{
                margin-top: 16px;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                cursor: pointer;
                justify-content: center;
            }
            &-default-switch{
                border: 1px solid #d9d9d9;
            }
            &-open-switch{
                border: 1px solid #4487DC;

            }
            &-btn{
                margin-top: 16px;
                height: 40px;
            }
        }
    }

}
