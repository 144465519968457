.grouped-assets {
    .to-vw(660);
}

.ungrouped-assets {
    .to-vw(260);
}

.groups {
    margin-right: -8px;
    padding-right: 8px;

    // overflow: auto;
    ::-webkit-scrollbar-button {
        height: 40px !important;
    }
}

.ungrouped-droppable {
    .to-vw(260) !important;
    display: flex;
    flex-flow: row wrap;
    .to-vw(-10, margin-right);
    min-height: 200px;
}

.group-drawer {

    .ant-drawer-body,
    .ant-drawer-content {
        overflow: hidden !important;
    }
}

.ungrouped-filter {
    .to-vw(248) !important;
    box-sizing: border-box;
}

.DraggableTags-tag {
    cursor: grab !important;
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;
    z-index: 30 !important;

    &:active {
        cursor: grabbing !important;
        cursor: -moz-grabbing !important;
        cursor: -webkit-grabbing !important;
    }
}

.DraggableTags-tag-drag {
    z-index: 100 !important;
}

.single-drop-area {
    .to-vh(144, min-height);
    .to-vw(660);
    border: 1px dashed #e0e0e0;
    margin-top: 12px;
    padding: 8px;
    box-sizing: border-box;
    position: relative;
}

.single-group-name {
    .ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0px !important;
    }
}

.drag-drop-area {
    display: flex;
    justify-content: space-between;
    margin-right: -40px;
    padding-right: 40px;
    overflow: auto;
    .to-vh(630);
}



.draggable-img-wrapper {
    background: black;
    .to-vw(120);
    .to-vw(120, height);
    margin: 2px 4px 2px 4px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        border: 2px solid #29c1b9;
    }

    img {
        width: 100%;
        height: 100%;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;

        &:hover {
            opacity: 0.5;
        }

        transition: opacity 500ms ease 0s,
        visibility 800ms ease 0s;
    }
}


.drag-placeholder {
    display: flex;
    .to-vh(144);
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;

    img {
        .to-vw(71);
        .to-vw(70, height);
    }
}

.drag-placeholder-none {
    display: none;
}
