.ant-table-column-has-sorters,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  box-sizing: border-box !important;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

.ant-table-column-sorters {
  padding-top: 0;
  padding-bottom: 0;
}

.table-row {
  cursor: pointer;
}

.plan-table {
  border: 1px solid #f2f2f2;
  margin-top: 16px;
  border-radius: 4px;
  .ant-table-placeholder {
    display: none;
  }
  .ant-table-thead > tr > th {
    background: transparent;
  }
}

.table-wrap {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}


.single-record {
  display: flex;
  align-items: center;

  min-height: 56px;
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.record-cell {
  box-sizing: border-box;
  padding: 2px;
}

.shooting-plan-detail-table {
  .ant-table-cell {
    border-right: none !important;
  }
  border-right: 1px solid #f0f0f0;
  border-radius: 4px;
}

.ant-tooltip {
  max-width: 400px !important;
  padding: 8px !important;
  box-sizing: border-box;
}

// .ant-tooltip-inner {
//   color: #6d6d6d;
//   background-color: white;
// }

.ant-tooltip-arrow-content {
  background-color: white !important;
}

.table-cursor-default {
  cursor: default;
}

.table-list-content {
  margin-top: 20px;
  font-size: 14px;
  height: 100%;
}

.record-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  &:hover {
    background-color: #fbfbfc;
  }
}

.permissions {
  height: 94vh;
  overflow: auto;
  margin-right: -49px;
  padding-right: 50px;
  box-sizing: border-box;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 12px 50px 12px 20px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.asset-table-row {
  .ant-checkbox {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
  }
}
.sortable-table {
  .ant-table-body {
    padding-bottom: 40px;
  }
  .ant-table {
    .ant-table-header::-webkit-scrollbar {
      width: 0px;
    }
    .ant-table-body, .ant-table-body-inner {
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: #909090;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 5px;
        background: #EDEDED;
      }
    }
    .ant-table-fixed-left, .ant-table-fixed-right {
      .ant-table-body-outer {
        margin-bottom: 0 !important;
      }
    }
  }
}

.ant-table-selection {
  padding-left: 18px;
}

.asset-table-row {
  td {
    border-bottom: none !important;
  }
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #D9D9D9;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 4px;
}

.detailsTable table > thead > tr th:first-child,
.detailsTable table > tbody > tr td:first-child {
  padding-left: 50px;
}

.reviewsTable table > tbody > tr > td {
  padding-top: 10px !important;
  padding-bottom: 3px !important;
}

