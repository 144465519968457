@import "@/stylesheets/global.less";
.amps-plan-task-img {
  width: 80px;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0.5px solid #bfc5d1;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  .task-img-body {
    width: 68px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    margin-top: 6px;
    overflow: hidden;
    .ant-image-mask-info{
        white-space: nowrap;
    }
    .assets-img {
      width: 35.77px;
      height: 51.35px;
    }
  }
  .task-text {
    font-family: Noto Sans SC;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    margin-top: 4px;
  }
  .border-pass {
    border: 1px solid #29c1b9;
  }
  .border-reject {
    border: 1px solid #fb3152;
  }
  .border-invalid {
    border: 1px solid #bfc5d1;
  }
  .background-invalid {
    background: rgba(0, 0, 0, 0.6);
  }
  .text-invalid {
    opacity: 0.6;
  }
  .assets-none {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    .icon-body {
      width: 100%;
      height: 100%;
    }
  }
}

.amps-plan-task-img:hover .assets-hover {
  display: block;
}
.maskImage {
  .preview-task-info-body {
    position: absolute;
    left: 150px;
    top: 100px;
    .preview-info {
      font-family: Noto Sans SC;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #ffffff;
      text-align: left;
      display: flex;
      margin-bottom: 4px;
      line-height: 20px;
      span {
        width: 50px;
        margin-right: 10px;
        display: block;
      }
    }
  }
}

#taskMasBody {
  background-color: rgba(0, 0, 0) !important;
}
